.specialists__card {
    max-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    width: 380px;
    padding: 20px;

    border-radius: 20px;
    background: var(--white, #ffffff);
    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

    .card__head {
        width: 380px;
        display: flex;
        flex-direction: row;
        gap: 20px;

        .card__headImg {
            width: 150px;
            height: 150px;
        }

        .card__headInfo {
            display: flex;
            flex-direction: column;
            max-width: 210px;

            .card__headInfo__title {
                max-width: 190px;
                margin-top: 10px;
                font-size: 16px;

                .card__headInfo__title_marked {
                    max-width: 190px;
                    hyphens: auto;
                }
            }

            .card__headInfo__subTitle {
                margin-top: 6px;
                margin-left: 15px;
                color: var(--blue);
                font-size: 18px;
                font-weight: 600;
            }



            .card__headInfo__text {
                margin-top: 10px;
                font-size: 14px;
            }
        }

        .spec_page {
            gap: 30px;

            .card__headInfo__title,
            .card__headInfo__subTitle {
                max-width: 220px;
                font-size: 22px;
                margin-left: 0;
            }
        }
    }

    .card__actionBtn {
        cursor: pointer;
        text-align: center;
        padding: 10px;
        width: 90%;
        color: var(--blue);
        font-size: 14px;
        font-weight: 600;
        border-radius: 100px;
        border: 1px solid var(--blue);

        transition: 0.2s;

        &:hover {
            border-color: var(--blue);
            background-color: var(--blue);
            color: var(--white);
        }

        &:active {
            transform: scale(99%);
        }
    }
}

@media screen and (max-width: 1366px) {
    .specialists__card {
        gap: 10px;
        width: 370px;
        .card__head {
            .card__headInfo {
                .card__headInfo__title {
                    .card__headInfo__title_marked {
                        hyphens: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1240px) {
    .specialists__card {
        max-width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 310px;
        padding: 20px;
    
        border-radius: 20px;
        background: var(--white, #ffffff);
        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);
    
        .card__head {
            width: 310px;
            display: flex;
            flex-direction: row;
            gap: 20px;
            padding-left: 30px;
    
            .card__headImg {
                width: 110px;
                height: 110px;
            }
    
            .card__headInfo {
                display: flex;
                flex-direction: column;
                max-width: 210px;
    
                .card__headInfo__title {
                    max-width: 190px;
                    margin-top: 10px;
                    font-size: 16px;
    
                    .card__headInfo__title_marked {
                        max-width: 190px;
                        hyphens: none;
                    }
                }
    
                .card__headInfo__subTitle {
                    margin-top: 6px;
                    margin-left: 15px;
                    font-size: 18px;
                    font-weight: 600;
                }
    
    
    
                .card__headInfo__text {
                    margin-top: 10px;
                    font-size: 14px;
                }
            }
    
            .spec_page {
                gap: 30px;
    
                .card__headInfo__title,
                .card__headInfo__subTitle {
                    max-width: 220px;
                    font-size: 22px;
                    margin-left: 0;
                }
            }
        }
    
        .card__actionBtn {
            text-align: center;
            padding: 10px;
            width: 90%;
            font-size: 14px;
            font-weight: 600;
            border-radius: 100px;
    
            transition: 0.2s;
    
            &:hover {
                color: var(--white);
            }
    
            &:active {
                transform: scale(99%);
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .specialists__card {
        max-width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 400px;
        padding: 20px;
    
        border-radius: 20px;
        background: var(--white, #ffffff);
        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);
    
        .card__head {
            width: 380px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            padding-left: 60px;
    
            .card__headImg {
                width: 90px;
                height: 90px;
            }
    
            .card__headInfo {
                display: flex;
                flex-direction: column;
                max-width: 210px;
    
                .card__headInfo__title {
                    max-width: 190px;
                    margin-top: 10px;
                    font-size: 16px;
    
                    .card__headInfo__title_marked {
                        max-width: 190px;
                        hyphens: none;
                    }
                }
    
                .card__headInfo__subTitle {
                    margin-top: 0px;
                    margin-left: 15px;
                    font-size: 18px;
                    font-weight: 600;
                }
    
    
    
                .card__headInfo__text {
                    margin-top: 10px;
                    font-size: 14px;
                }
            }
    
            .spec_page {
                gap: 30px;
    
                .card__headInfo__title,
                .card__headInfo__subTitle {
                    max-width: 220px;
                    font-size: 22px;
                    margin-left: 0;
                }
            }
        }
    
        .card__actionBtn {
            text-align: center;
            padding: 10px;
            width: 90%;
            font-size: 14px;
            font-weight: 600;
            border-radius: 100px;
    
            transition: 0.2s;
    
            &:hover {
                color: var(--white);
            }
    
            &:active {
                transform: scale(99%);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .specialists__card {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 350px;
        padding: 20px;
    
        border-radius: 20px;
        background: var(--white, #ffffff);
        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);
    
        .card__head {
            width: 350px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            padding-left: 0px;
    
            .card__headImg {
                width: 90px;
                height: 90px;
            }
    
            .card__headInfo {
                display: flex;
                flex-direction: column;
                max-width: 210px;
    
                .card__headInfo__title {
                    max-width: 190px;
                    margin-top: 10px;
                    font-size: 16px;
    
                    .card__headInfo__title_marked {
                        max-width: 200px;
                        hyphens: none;
                    }
                }
    
                .card__headInfo__subTitle {
                    margin-top: 0px;
                    margin-left: 15px;
                    font-size: 18px;
                    font-weight: 600;
                }
    
    
    
                .card__headInfo__text {
                    margin-top: 10px;
                    font-size: 14px;
                }
            }
    
            .spec_page {
                gap: 30px;
    
                .card__headInfo__title,
                .card__headInfo__subTitle {
                    max-width: 220px;
                    font-size: 22px;
                    margin-left: 0;
                }
            }
        }
    
        .card__actionBtn {
            text-align: center;
            padding: 10px;
            width: 90%;
            font-size: 14px;
            font-weight: 600;
            border-radius: 100px;
    
            transition: 0.2s;
    
            &:hover {
                color: var(--white);
            }
    
            &:active {
                transform: scale(99%);
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .specialists__card {
        max-width: 305px;
        padding: 20px;

        border-radius: 20px;
        background: var(--white, #ffffff);
        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

        .card__head {
            width: 305px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 15px;

            .card__headImg {
                width: 120px;
                height: 120px;
            }

            .card__headInfo {
                display: flex;
                flex-direction: column;
                max-width: 160px;

                .card__headInfo__title {
                    max-width: 190px;
                    margin-top: 10px;
                    font-size: 16px;
                }

                .card__headInfo__subTitle {
                    margin-top: 6px;
                    margin-left: 15px;
                    font-size: 18px;
                    font-weight: 600;
                }

                .card__headInfo__text {
                    display: none;
                }
            }
        }

        .card__actionBtn {
            text-align: center;
            padding: 10px;
            width: 90%;
            font-size: 14px;
            font-weight: 600;
            border-radius: 100px;

            transition: 0.2s;

            &:hover {
                color: var(--white);
            }

            &:active {
                transform: scale(99%);
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .specialists__card {
        max-width: 250px;
        padding: 20px;

        border-radius: 20px;
        background: var(--white, #ffffff);
        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

        .card__head {
            width: 250px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5px;

            .card__headImg {
                width: 100px;
                height: 100px;
            }

            .card__headInfo {
                display: flex;
                flex-direction: column;
                max-width: 160px;
                gap: 5px;

                .card__headInfo__title {
                    max-width: 190px;
                    margin-bottom: 20px;
                    margin-top: 0px;
                    font-size: 16px;
                }

                .card__headInfo__subTitle {
                    margin-top: 6px;
                    margin-left: 15px;
                    font-size: 14px;
                    font-weight: 600;
                }

                .card__headInfo__text {
                    display: none;
                }
            }
        }

        .card__actionBtn {
            text-align: center;
            padding: 10px;
            width: 90%;
            font-size: 14px;
            font-weight: 600;
            border-radius: 100px;

            transition: 0.2s;

            &:hover {
                color: var(--white);
            }

            &:active {
                transform: scale(99%);
            }
        }
    }
}