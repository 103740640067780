.default__inp {
    width: 300px;
    border-radius: 50px;
    background: var(--light-gray);
    padding: 15px 20px;

    font-weight: 500;

    transition: 0.2s;
    border: 1px solid transparent;

    &:hover {
        border: 0.5px solid #d0cde1;
    }

    &:focus {
        border: 0.5px solid #d0cde1;
    }
}

.overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(176, 170, 208, 0.3);
    backdrop-filter: blur(5px);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: 0.3s;

    z-index: 100;

    .writeDrawer {
        width: 700px;
        height: 440px;

        display: flex;
        flex-direction: column;

        padding: 20px 30px;
        border-radius: 6px;
        background: #fff;

        font-family: "Montserrat", sans-serif;

        .writeDrawer__head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            user-select: none;

            .writeDrawer__headText {
                color: var(--black);
                font-size: 18px;
                font-weight: 600;
            }

            .writeDrawer__headBtn {
                cursor: pointer;
                user-select: none;

                &:hover {
                    svg {
                        path {
                            transition: 0.2s;
                            fill: #ff3b3b;
                        }
                    }
                }

                &:focus {
                    svg {
                        path {
                            transition: 0.2s;
                            fill: #ff3b3b;
                        }
                    }
                }
            }
        }

        .writeDrawer__contacts {
            margin-top: 50px;

            display: flex;
            flex-direction: row;
            gap: 30px;
        }

        .writeDrawer__text {
            margin-top: 20px;

            resize: none;
            border-radius: 20px;
            background: var(--light-gray);
            padding: 15px 20px;

            font-size: 16px;
            font-weight: 500;
            font-family: "Montserrat", sans-serif;

            transition: 0.2s;
            border: 1px solid transparent;

            &:hover {
                border: 0.5px solid #d0cde1;
            }

            &:focus {
                border: 0.5px solid #d0cde1;
            }
        }

        .writeDrawer__foot {
            margin-top: 20px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;

            .writeDrawer__foot__personalAgreement {
                text-align: center;
                font-size: 14px;
                max-width: 60%;
                .writeDrawer__foot__personalAgreement__link {
                    font-weight: 700;
                    transition: 0.15s;

                    &:hover {
                        color: var(--light-blue);
                    }
                }
            }

            .writeDrawer__foot__send {
                cursor: pointer;
                width: fit-content;
                padding: 15px 50px;
                color: var(--white);
                font-weight: 700;
                text-align: center;
                border-radius: 50px;
                background: var(--blue);

                transition: 0.1s;

                &:hover {
                    background: var(--light-blue);
                }

                &:active {
                    background: var(--light-blue);
                    transform: scale(99%);
                }
            }
        }
    }

    //onClick={(e) => setRequestOverlay(true)}

    .writeDrawerAccepted {
        width: 700px;
        height: 250px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        padding: 20px 30px;
        border-radius: 6px;
        background: #fff;

        font-family: "Montserrat", sans-serif;

        .writeDrawerAccepted__head {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            user-select: none;

            .writeDrawerAccepted__headText {
                color: var(--black);
                font-size: 18px;
                font-weight: 600;
            }

            .writeDrawerAccepted__headBtn {
                cursor: pointer;
                user-select: none;

                &:hover {
                    svg {
                        path {
                            transition: 0.2s;
                            fill: #ff3b3b;
                        }
                    }
                }

                &:focus {
                    svg {
                        path {
                            transition: 0.2s;
                            fill: #ff3b3b;
                        }
                    }
                }
            }
        }

        .writeDrawerAccepted__title {
            font-size: 20px;
            font-weight: 500;
        }

        .writeDrawerAccepted__text {
            width: 100%;
            text-align: center;
        }
    }
}

@media screen and (max-width: 600px) {
    .overlay {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(176, 170, 208, 0.3);
        backdrop-filter: blur(5px);

        display: flex;
        justify-content: center;
        align-items: center;

        transition: 0.3s;

        z-index: 100;

        .writeDrawer {
            width: 520px;
            height: 440px;

            display: flex;
            flex-direction: column;

            padding: 20px 30px;
            border-radius: 6px;
            background: #fff;

            font-family: "Montserrat", sans-serif;

            .writeDrawer__head {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                user-select: none;

                .writeDrawer__headText {
                    color: var(--black);
                    font-size: 18px;
                    font-weight: 600;
                }

                .writeDrawer__headBtn {
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }

                    &:focus {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }
                }
            }

            .writeDrawer__contacts {
                margin-top: 50px;

                display: flex;
                flex-direction: row;
                gap: 30px;
            }

            .writeDrawer__text {
                margin-top: 20px;

                resize: none;
                border-radius: 20px;
                background: var(--light-gray);
                padding: 15px 20px;

                font-size: 16px;
                font-weight: 500;
                font-family: "Montserrat", sans-serif;

                transition: 0.2s;
                border: 1px solid transparent;

                &:hover {
                    border: 0.5px solid #d0cde1;
                }

                &:focus {
                    border: 0.5px solid #d0cde1;
                }
            }

            .writeDrawer__foot {
                flex-direction: column;
                gap: 10px;
                .writeDrawer__foot__personalAgreement {
                    font-size: 12px;
                }
                .writeDrawer__foot__send {
                    margin-top: 0px;

                    width: fit-content;
                    padding: 15px 50px;
                    color: var(--white);
                    font-weight: 700;
                    text-align: center;
                    border-radius: 50px;

                    transition: 0.1s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }
        }

        //onClick={(e) => setRequestOverlay(true)}

        .writeDrawerAccepted {
            width: 520px;
            height: 250px;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            padding: 20px 30px;
            border-radius: 6px;
            background: #fff;

            font-family: "Montserrat", sans-serif;

            .writeDrawerAccepted__head {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                user-select: none;

                .writeDrawerAccepted__headText {
                    color: var(--black);
                    font-size: 18px;
                    font-weight: 600;
                }

                .writeDrawerAccepted__headBtn {
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }

                    &:focus {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }
                }
            }

            .writeDrawerAccepted__title {
                font-size: 20px;
                font-weight: 500;
            }

            .writeDrawerAccepted__text {
                width: 100%;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .default__inp {
        width: 230px;
        border-radius: 50px;
        background: var(--light-gray);
        padding: 15px 20px;

        font-weight: 500;

        transition: 0.2s;
        border: 1px solid transparent;

        &:hover {
            border: 0.5px solid #d0cde1;
        }

        &:focus {
            border: 0.5px solid #d0cde1;
        }
    }

    .overlay {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(176, 170, 208, 0.3);
        backdrop-filter: blur(5px);

        display: flex;
        justify-content: center;
        align-items: center;

        transition: 0.3s;

        z-index: 100;

        .writeDrawer {
            width: 270px;
            height: 460px;

            display: flex;
            flex-direction: column;

            padding: 20px 30px;
            border-radius: 6px;
            background: #fff;

            font-family: "Montserrat", sans-serif;

            .writeDrawer__head {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                user-select: none;

                .writeDrawer__headText {
                    color: var(--black);
                    font-size: 18px;
                    font-weight: 600;
                }

                .writeDrawer__headBtn {
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }

                    &:focus {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }
                }
            }

            .writeDrawer__contacts {
                margin-top: 20px;

                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            .writeDrawer__text {
                margin-top: 20px;

                resize: none;
                border-radius: 20px;
                background: var(--light-gray);
                padding: 15px 20px;

                font-size: 16px;
                font-weight: 500;
                font-family: "Montserrat", sans-serif;

                transition: 0.2s;
                border: 1px solid transparent;

                &:hover {
                    border: 0.5px solid #d0cde1;
                }

                &:focus {
                    border: 0.5px solid #d0cde1;
                }
            }

            .writeDrawer__foot__send {
                margin-top: 20px;

                align-self: center;
                width: fit-content;
                padding: 15px 50px;
                color: var(--white);
                font-weight: 700;
                text-align: center;
                border-radius: 50px;

                transition: 0.1s;

                &:hover {
                }

                &:active {
                    transform: scale(99%);
                }
            }
        }

        //onClick={(e) => setRequestOverlay(true)}

        .writeDrawerAccepted {
            width: 300px;
            height: 250px;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            padding: 20px 30px;
            border-radius: 6px;
            background: #fff;

            font-family: "Montserrat", sans-serif;

            .writeDrawerAccepted__head {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                user-select: none;

                .writeDrawerAccepted__headText {
                    color: var(--black);
                    font-size: 18px;
                    font-weight: 600;
                }

                .writeDrawerAccepted__headBtn {
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }

                    &:focus {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #ff3b3b;
                            }
                        }
                    }
                }
            }

            .writeDrawerAccepted__title {
                font-size: 20px;
                font-weight: 500;
            }

            .writeDrawerAccepted__text {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.overlay__visible {
    visibility: visible;
    opacity: 1;
}
