.radioGroup {
    input[type="radio"] {
        display: none;
    }

    label {
        white-space: nowrap;
        font-size: 15px;
        font-weight: 500;
        color: var(--black);
        display: inline-block;
        cursor: pointer;
        padding: 5px 15px;
        line-height: 34px;
        border: 1px solid #999;
        border-radius: 50px;
        user-select: none;
        transition: 0.2s;
    }

    input[type="radio"]:checked+label {
        color: var(--white);
        background-color: var(--blue);
        border-color: var(--blue);
    }

    &:hover {
        input[type="radio"]+label {
            color: var(--white);
            background-color: var(--blue);
            border-color: var(--blue);
        }
    }

    .selected {
        color: var(--white);
        background-color: var(--blue);
        border-color: var(--blue);
    }
}