.overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(176, 170, 208, 0.30);
    backdrop-filter: blur(5px);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: .3s;

    z-index: 100;

    .town__select {
        display: flex;
        flex-direction: column;

        width: 520px;
        height: 625px;
        padding: 20px 30px;

        border-radius: 6px;
        background: #FFF;

        font-family: 'Montserrat', sans-serif;

        .town__selectHead {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            user-select: none;

            .town__selectHead__text {
                color: var(--black);
                font-size: 18px;
                font-weight: 600;
            }

            .town__selectHead__currentTown {
                color: var(--blue);
                font-size: 16px;
                font-weight: 700;
                padding: 10px 10px;
                border-radius: 50px;

                margin-right: auto;
                margin-left: 6px;
            }

            .town__selectHead__btn {

                .town__selectHead__btnImg {
                    cursor: pointer;
                    user-select: none;
                }

                &:hover {
                    svg {
                        path {
                            transition: .2s;
                            fill: #ff3b3b;
                        }
                    }
                }
                &:focus {
                    svg {
                        path {
                            transition: .2s;
                            fill: #ff3b3b;
                        }
                    }
                }
            }
        }

        .town__selectSearch {
            margin-top: 40px;

            position: relative;

            .town__selectSearch__input {
                position: absolute;
                left: 0;
                top: -1px;
                width: 70%;

                padding: 12px 140px 12px 15px;

                color: var(--dark-gray);

                font-size: 16px;
                font-weight: 500;

                border-radius: 50px;
                background: var(--light-gray);
                border: 1px solid #fff;

                transition: .2s;

                &:hover {
                    border: .5px solid #D0CDE1;
                }

                &:focus {
                    border: .5px solid #D0CDE1;
                }
            }

            .town__selectSearch__btn {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;

                padding: 12px 40px;

                color: var(--white);

                font-size: 16px;
                font-weight: 700;

                border-radius: 50px;
                background: var(--blue);

                transition: .1s;

                &:hover {
                    background: var(--light-blue);
                    transform: scale(101%);
                }

                &:active {
                    transform: scale(99%);
                    background: #E28424;
                }
            }

        }

        .town__selectTowns {
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow-y: scroll;

            .town__selectTown {
                cursor: pointer;

                transition: .2s;

                &:hover {
                    font-weight: 700;
                    color: var(--blue);
                }
            }

        }

    }
}

.overlay__visible {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 480px) {
    .overlay {

        .town__select {
            margin-top: 40px;
            width: 320px;
            height: 500px;


            .town__selectHead {


                .town__selectHead__text {}

                .town__selectHead__currentTown {}

                .town__selectHead__btn {

                    .town__selectHead__btnImg {}
                }
            }

            .town__selectSearch {


                .town__selectSearch__input {
                    width: 40%;
                    padding: 12px 70px 12px 15px;
                }

                .town__selectSearch__btn {}

            }

            .town__selectTowns {


                .town__selectTown {}

            }

        }
    }
}