.App {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
}

.container {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0;
}

.section__banner {
    // margin-top: 60px;
    padding-top: 120px;
    min-height: calc(100vh - 120px);
    font-family: "Montserrat", sans-serif;
    background: var(--super-light-gray);
    transition: 1s;

    .banner-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .banner__info {
            display: flex;
            flex-direction: column;
            width: 640px;

            .banner__infoTitle {
                color: var(--black);
                font-size: 36px;
                font-weight: 700;

                .banner__infoTitle_marked {
                    color: var(--blue);
                }
            }

            .banner__infoText {
                font-weight: 500;
                margin-top: 30px;
                max-width: 500px;
                line-height: 180%;
            }

            .banner__form {
                .banner__actionInp {
                    margin-top: 26px;
                    display: flex;
                    flex-direction: row;
                    gap: 30px;
                    align-items: center;

                    color: var(--dark-gray);
                    font-size: 16px;
                    font-weight: 500;

                    .banner__actionInp__num {
                        width: 300px;
                        border-radius: 50px;
                        background: var(--light-gray);
                        padding: 15px 20px;

                        transition: 0.2s;
                        border: 1px solid transparent;

                        &:hover {
                            border: 0.5px solid #d0cde1;
                        }

                        &:focus {
                            border: 0.5px solid #d0cde1;
                        }
                    }

                    .banner__actionInp__email {
                        width: 300px;

                        border-radius: 50px;
                        background: var(--light-gray);
                        padding: 15px 20px;

                        transition: 0.2s;

                        border: 1px solid transparent;

                        &:hover {
                            border: 0.5px solid #d0cde1;
                        }

                        &:focus {
                            border: 0.5px solid #d0cde1;
                        }
                    }
                }

                .banner__specText {
                    font-weight: 500;
                    margin-top: 30px;
                }

                .banner__specRadioGroup {
                    margin-top: 30px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                    flex-wrap: wrap;
                    gap: 14px;

                    .banner__specRadioGroup__btnAllSpec {
                        font-size: 15px;
                        font-weight: 500;
                        height: 100%;
                        padding: 10px 20px;
                        border: 1px solid var(--blue);
                        border-radius: 50px;
                        user-select: none;
                        color: var(--blue);

                        transition: 0.15s;

                        &:hover {
                            color: var(--white);
                            background-color: var(--blue);
                            border-color: var(--blue);
                        }

                        &:active {
                            transform: scale(99%);
                        }
                    }
                }

                .banner__formBottom {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;

                    font-size: 16px;
                    font-weight: 500;

                    .banner__formBottom__inpTown {
                        width: 100%;
                        padding: 15px;
                        border-radius: 50px;
                        background: var(--light-gray, #f2f0fe);
                        border: 1px solid transparent;

                        transition: 0.2s;

                        &:hover {
                            border: 0.5px solid #d0cde1;
                        }

                        &:focus {
                            border: 0.5px solid #d0cde1;
                        }
                    }

                    .banner__formBottom__inpCount {
                        width: 100%;
                        padding: 15px;
                        border-radius: 50px;
                        background: var(--light-gray, #f2f0fe);
                        border: 1px solid transparent;

                        transition: 0.2s;

                        &:hover {
                            border: 0.5px solid #d0cde1;
                        }

                        &:focus {
                            border: 0.5px solid #d0cde1;
                        }
                    }

                    .banner__formBottom__btnSend {
                        cursor: pointer;
                        width: 100%;
                        padding: 15px;
                        color: var(--white);
                        font-weight: 700;
                        text-align: center;
                        border-radius: 50px;
                        background: var(--blue);

                        transition: 0.15s;

                        &:hover {
                            background: var(--light-blue);
                        }

                        &:active {
                            transform: scale(99%);
                        }
                    }
                }
            }
        }

        .banner__img {
            position: relative;
            width: 600px;
            height: 630px;

            .banner__imgGroup {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.section__specialists {
    margin-top: 120px;
    font-family: "Montserrat", sans-serif;

    .specialists-wrap {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .specialists__title {
            font-size: 24px;
            font-weight: 600;
        }

        .specialists__cards {
            display: flex;
            flex-direction: row;
            gap: 30px;
            flex-wrap: wrap;

            .specialists__cardMore {
                max-width: 30%;
                width: 380px;
                padding: 20px;

                display: flex;
                justify-content: center;
                align-items: center;

                border-radius: 20px;
                background: var(--white, #ffffff);
                box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                .specialists__cardMore__btn {
                    color: var(--blue);
                    font-size: 18px;
                    font-weight: 600;

                    transition: 0.2s;

                    &:hover {
                        color: var(--light-blue);
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }
        }
    }
}

.section__clientInfo {
    margin-top: 160px;
    padding-bottom: 60px;
    font-family: "Montserrat", sans-serif;

    .clientInfo-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .clientInfo__img {
            position: relative;
            width: 640px;
            height: 660px;

            .clientInfo__imgGroup {
                position: absolute;
                top: -40px;
            }
        }

        .clientInfo__content {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .clientInfo__contentTitle {
                max-width: 480px;
                width: 100%;
                font-size: 24px;
                font-weight: 700;
            }

            .clientInfo__contentList {
                display: flex;
                flex-direction: column;
                gap: 48px;

                .clientInfo__contentList__item {
                    display: flex;
                    flex-direction: row;
                    gap: 14px;

                    .clientInfo__contentList__item_text {
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .clientInfo__contentList__item_mark {
                        min-width: 20px;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: var(--blue);
                    }
                }
            }
        }
    }
}

.section__stats {
    padding-top: 120px;
    padding-bottom: 120px;
    font-family: "Montserrat", sans-serif;
    background-color: #f7f6ff;

    .stats-wrap {
        display: flex;
        flex-direction: row;
        gap: 200px;

        .stats__left {
            display: flex;
            flex-direction: column;

            .stats__leftTitle {
                width: 500px;
                color: var(--black);
                font-size: 24px;
                font-weight: 600;

                .stats__leftTitle_marked {
                    color: var(--blue);
                    font-weight: 800;
                }
            }

            .stats__leftList {
                margin-top: 100px;

                display: flex;
                flex-direction: column;
                gap: 100px;

                .stats__leftList__item {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 20px;

                    .stats__leftList__itemNum {
                        position: relative;
                        top: -30px;
                        color: var(--blue);
                        font-size: 100px;
                        font-weight: 900;

                        &::before {
                            position: absolute;
                            content: "";
                            width: 100px;
                            height: 100px;
                            left: 0;
                            top: 40px;
                            border-radius: 6px;
                            background: rgba(22, 164, 209, 0.2);
                            animation-name: flicker;
                            animation-duration: 2s;
                            animation-iteration-count: infinite;
                        }
                    }

                    .stats__leftList__itemText {
                        max-width: 300px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 180%;
                    }
                }
            }

            .stats__leftBtn {
                cursor: pointer;
                margin-top: 60px;

                align-self: center;
                color: var(--white);
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                padding: 20px 60px;
                border-radius: 50px;
                background: var(--blue);

                transition: 0.15s;

                &:hover {
                    background: var(--light-blue);
                }

                &:active {
                    background: var(--light-blue);
                    transform: scale(99%);
                }
            }
        }

        .stats__right {
            display: flex;
            flex-direction: column;

            .stats__rightTitle {
                color: var(--black);
                font-size: 24px;
                font-weight: 600;

                .stats__rightTitle_marked {
                    color: var(--blue);
                    font-weight: 800;
                }
            }

            .stats__rightList {
                margin-top: 100px;
                display: flex;
                flex-direction: column;
                gap: 100px;

                .stats__rightList__item {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 20px;

                    .stats__rightList__itemNum {
                        position: relative;
                        top: -30px;
                        color: var(--blue);
                        font-size: 100px;
                        font-weight: 900;

                        &::before {
                            position: absolute;
                            content: "";
                            width: 100px;
                            height: 100px;
                            left: 23px;
                            top: 40px;
                            border-radius: 6px;
                            background: rgba(22, 164, 209, 0.2);

                            animation-name: flicker;
                            animation-duration: 2s;
                            animation-iteration-count: infinite;
                            animation-delay: 1s;
                        }
                    }

                    .stats__rightList__itemText {
                        max-width: 460px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 180%;
                    }
                }
            }

            .stats__rightBtn {
                cursor: pointer;
                margin-top: 60px;
                align-self: center;
                color: var(--white);
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                padding: 20px 60px;
                border-radius: 50px;
                background: var(--blue);

                transition: 0.15s;

                &:hover {
                    background: var(--light-blue);
                }

                &:active {
                    background: var(--light-blue);
                    transform: scale(99%);
                }
            }
        }
    }
}

.section__partners {
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: "Montserrat", sans-serif;
    background-color: #f9f9fc;

    .partners-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .partners__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 180%;
        }

        .partners__cards {
            margin-top: 70px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 60px;

            .partners__card {
                padding: 10px;
                border-radius: 6px;
                background: #fff;

                box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                transition: 0.3s;

                &:hover {
                    transform: translate(-1px, -1px);
                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.16);
                }
            }
        }

        .partners__text {
            margin-top: 100px;
            color: #222;
            font-size: 24px;
            font-weight: 600;
            line-height: 180%;

            .partners__text_marked {
                color: var(--blue);
                font-size: 32px;
                font-weight: 800;
                line-height: 180%;
            }
        }
    }
}

.section__coop {
    padding-top: 100px;
    padding-bottom: 200px;
    font-family: "Montserrat", sans-serif;
    background-color: #f7f6ff;

    .coop-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .coop__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 180%;
            /* 43.2px */
            letter-spacing: 0.48px;
        }

        .coop__content {
            width: 100%;

            margin-top: 110px;
            display: flex;
            flex-direction: row;
            gap: 90px;

            .coop__leftCards {
                display: flex;
                flex-direction: column;
                gap: 80px;

                .coop_card {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    border-radius: 20px;
                    background: #f2f0fe;
                    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                    width: 530px;
                    height: 90px;

                    .coop_cardImg {
                        position: relative;
                        top: -24px;
                        left: -20px;
                        width: 92px;
                        height: 87px;

                        transition: 0.2s;
                    }

                    .coop_cardText {
                        // width: 400px;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .coop_cardBackImg {
                        position: absolute;
                        right: -80px;
                        top: 14px;
                        z-index: 11;
                        transition: 0.2s;
                    }

                    transition: 0.2s;
                    &:hover {
                        .coop_cardImg {
                            position: relative;
                            top: -26px;
                            left: -22px;
                        }
                        .coop_cardBackImg {
                            right: -84px;
                        }
                    }
                }

                .card1 {
                    position: relative;
                    left: 200px;
                }

                .card2 {
                    position: relative;
                    left: 55px;
                }

                .card3 {
                    position: relative;
                    left: 43px;
                }

                .card4 {
                    position: relative;
                    left: 120px;
                }
            }

            .coop__img {
                position: relative;
                width: 420px;
                height: 440px;

                .coop__imgAbs {
                    position: absolute;
                    transition: 0.2s;
                }

                .coop__imgGroup {
                    z-index: 1;
                    top: 0px;
                    left: -20px;
                }

                .coop__imgBtn {
                    cursor: pointer;
                    text-align: center;
                    width: 300px;
                    z-index: 12;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 700;
                    padding: 10px;
                    border-radius: 50px;
                    background: var(--blue);

                    left: 160px;
                    bottom: -250px;

                    transition: 0.15s;

                    &:hover {
                        background: var(--light-blue);
                    }

                    &:active {
                        background: var(--light-blue);
                        transform: scale(99%);
                    }
                }
            }
        }
    }
}

.section__map {
    position: static;
    padding-top: 150px;
    padding-bottom: 300px;
    height: 800px;
    font-family: "Montserrat", sans-serif;

    .mapWrap {
        display: flex;
        flex-direction: column;
        gap: 80px;
        align-items: center;

        .map__title {
            font-size: 24px;
            font-weight: 600;
            line-height: 180%;
            letter-spacing: 0.48px;
        }

        .map__bottom {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .map__bottom__text {
                position: relative;
                font-size: 16px;
                font-weight: 500;
                line-height: 180%;
                letter-spacing: 0.32px;
                padding-left: 30px;
                height: 28px;

                &::before {
                    position: absolute;
                    content: "";
                    width: 20px;
                    height: 20px;
                    left: 0;
                    top: 2.5px;
                    border-radius: 100px;
                    background: var(--blue);
                }
            }

            .map__bottom__box {
                position: relative;
                z-index: 10;
                visibility: hidden;
                opacity: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 30px 20px;
                max-width: 300px;
                height: 100%;
                border-radius: 20px;
                background: #f2f0fe;

                transition: 0.1s;
                box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                .map__bottom__box__closeBtn {
                    position: absolute;
                    content: "";
                    right: 15px;
                    top: 15px;
                    width: 16px;
                    height: 16px;

                    &:hover {
                        svg {
                            path {
                                transition: 0.2s;
                                fill: #a0a0a0;
                            }
                        }
                    }
                }
            }

            .visible {
                visibility: visible;
                opacity: 1;
            }

            // .map__bottomInfo__box {
            //     position: relative;
            //     width: 300px;
            //     height: 186px;
            //     left: 0;
            //     top: 60px;
            //     border-radius: 20px;
            //     background: #F2F0FE;
            //     box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);
            // }
        }

        .map__bottomMedia {
            display: none;

            .map__bottomMedia__search {
                position: relative;
                display: flex;
                flex-direction: column;

                .map__bottomMedia__searchInp {
                    color: var(--dark-gray);
                    width: 250px;
                    border-radius: 50px;
                    background: var(--light-gray);
                    padding: 15px 50px 15px 20px;

                    transition: 0.2s;
                    border: 1px solid transparent;

                    &:hover {
                        border: 0.5px solid #d0cde1;
                    }

                    &:focus {
                        border: 0.5px solid #d0cde1;
                    }
                }

                .map__bottomMedia__icon {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 0;
                    width: 50px;
                    height: 50px;

                    .map__bottomMedia__iconPath1 {
                        position: absolute;
                        width: 2px;
                        height: 14px;
                        top: 20px;
                        left: 10px;
                        background: var(--green);
                        transform: rotate(-45deg);

                        transition: 0.3s;
                    }

                    .map__bottomMedia__iconPath2 {
                        position: absolute;
                        width: 2px;
                        height: 14px;
                        top: 20px;
                        right: 28px;
                        background: var(--green);
                        transform: rotate(45deg);

                        transition: 0.3s;
                    }
                }

                .btn__open {
                    .map__bottomMedia__iconPath1 {
                        transform: rotate(-135deg);
                    }

                    .map__bottomMedia__iconPath2 {
                        transform: rotate(135deg);
                    }
                }

                .map__bottomMedia__searchDropDown {
                    // visibility: hidden;
                    // opacity: 0;
                    z-index: 100;
                    position: absolute;
                    content: "";
                    left: 4%;
                    top: 60px;
                    width: 80%;
                    max-height: 180px;
                    overflow-y: scroll;
                    height: fit-content;
                    background: var(--light-gray);
                    opacity: 1;
                    border-radius: 20px;
                    padding: 20px;

                    -webkit-transform: scaleY(0);
                    -ms-transform: scaleY(0);
                    transform: scaleY(0);
                    -webkit-transform-origin: 0 0;
                    -ms-transform-origin: 0 0;
                    transform-origin: 0 0;
                    transition: 0.5s;

                    .map__bottomMedia__searchDropDown__item {
                        padding: 6px 0;
                        transition: 0.3s;

                        &:hover {
                            color: var(--green);
                        }
                    }
                }

                .open {
                    -webkit-transform: scaleY(1);
                    -ms-transform: scaleY(1);
                    transform: scaleY(1);

                    // visibility: visible;
                    // opacity: 1;
                }

                .map__bottomMedia__text {
                    margin-top: 40px;
                    position: relative;
                    max-width: 80%;
                    text-align: center;
                    align-self: center;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 20px;
                        height: 20px;
                        left: -30px;
                        top: 0px;
                        border-radius: 100px;
                        background: #fba457;
                    }
                }

                .map__bottomMedia__box {
                    margin-top: 40px;
                    position: relative;
                    z-index: 10;
                    visibility: hidden;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    padding: 30px 20px;
                    max-width: 280px;
                    height: 100%;
                    border-radius: 20px;
                    background: #f2f0fe;

                    transition: 0.1s;
                    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                    .map__bottomMedia__box__closeBtn {
                        position: absolute;
                        content: "";
                        right: 15px;
                        top: 15px;
                        width: 16px;
                        height: 16px;

                        &:hover {
                            svg {
                                path {
                                    transition: 0.2s;
                                    fill: #a0a0a0;
                                }
                            }
                        }
                    }

                    .map__bottomMedia__box__addr {
                        max-width: 90%;
                    }
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .rf-map .district b {
            width: 28px;
            height: 28px;
            left: 6px;
            top: 28px;
        }

        .rf-map .district span {
            top: 30px;
            left: 46px;
            font-size: 20px;
            font-weight: normal;
        }

        .rf-map .district-text {
            left: 30px;
            top: 80px;
            width: calc(100% - 60px);
            height: calc(100% - 130px);
            font-size: 16px;
        }

        .rf-map .close-district {
            width: 60px;
            height: 60px;
            font-size: 60px;
        }

        .rf-map .district-links {
            display: none;
        }
    }

    /* Цвета регионов */
    // .rf-map [data-code="RU-SAR"] {
    //     fill: #116f21;
    //     cursor: pointer;
    // }

    // .rf-map [data-code="RU-SA"] {
    //     fill: #d17520;
    //     cursor: pointer;
    // }

    // .rf-map [data-code="RU-KO"] {
    //     fill: #901aad;
    // }

    // .rf-map [data-code="RU-SAR"]:hover {
    //     fill: #1bad33;
    // }

    // .rf-map [data-code="RU-SA"]:hover {
    //     fill: #e3954b;
    // }

    // .rf-map [data-code="RU-KO"]:hover {
    //     fill: #bf37e1;
    // }
}

.about {
    padding-top: 120px;
    padding-bottom: 350px;
    font-family: "Montserrat", sans-serif;
    transition: 1s;

    .aboutWrap {
        display: flex;
        flex-direction: row;
        gap: 100px;

        .about__info {
            max-width: 640px;
            display: flex;
            flex-direction: column;
            gap: 35px;

            .about__infoTitle {
                color: #222;
                font-size: 36px;
                font-weight: 700;
                letter-spacing: 1.8px;

                .about__infoTitle_marked {
                    color: #1dbf73;
                }
            }

            .about__infoSubTitle {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.32px;
            }

            .about__infoText {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.28px;
            }
        }

        .about__img {
            width: 600px;
            height: 600px;
            position: relative;

            &::before {
                position: absolute;
                content: "";
                width: 420px;
                height: 440px;
                left: 140px;
                top: 240px;
                border-radius: 50%;
                background: #a8a8a831;
            }

            .about__imgAddr {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 15px;
                left: 220px;

                .about__imgAddrText {
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0.32px;

                    .about__imgAddrText_marked {
                        font-weight: 500;
                    }
                }
            }

            .about__imgAbs {
                position: absolute;
            }

            .a1 {
                top: 120px;
                left: 10px;
            }

            .a2 {
                left: 140px;
                top: 240px;
            }

            .a3 {
                right: -30px;
                top: 400px;
            }

            .a4 {
                right: 50px;
                bottom: -158px;
            }

            .a5 {
                top: 540px;
                left: 140px;
            }

            .a6 {
                width: 60px;
                height: 30px;
                left: 130px;
                bottom: -60px;
            }

            .a7 {
                right: 60px;
                top: 200px;
            }

            .about__imgBtn {
                text-align: center;
                width: 300px;
                z-index: 12;
                color: var(--white);
                font-size: 16px;
                font-weight: 700;
                padding: 10px;
                border-radius: 50px;
                background: #fba457;

                left: 180px;
                bottom: -220px;

                transition: 0.15s;

                &:hover {
                    background: #e28424;
                }

                &:active {
                    background: #e28424;
                    transform: scale(99%);
                }
            }
        }

        .about__addrAdaptive {
            display: none;
        }
    }
}

.specialties {
    padding-top: 120px;
    padding-bottom: 380px;
    font-family: "Montserrat", sans-serif;
    transition: 1s;

    .specialtiesWrap {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .specialties__title {
            font-size: 36px;
            font-weight: 600;
            letter-spacing: 0.72px;

            .specialties__title_marked {
                color: var(--blue);
            }
        }

        .specialties__cards {
            display: flex;
            flex-direction: row;
            gap: 30px;
            flex-wrap: wrap;
        }
    }
}

.persAgr {
    padding-top: 120px;
    padding-bottom: 380px;

    font-family: "Montserrat", sans-serif;

    .persAgr__wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .persAgr__wrap__title {
            font-size: 36px;
            text-align: center;
        }
        .persAgr__wrap__text {
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
                letter-spacing: 0.8px;
                line-height: 150%;
                text-align: justify;
            }

            .par {
                margin-top: 10px;

                &:first-child {
                    margin-top: 0px;
                }
            }

            .sub {
                padding-left: 20px;
            }
        }
    }
}

.typed-cursor {
    display: none;
    animation: none;
}

.typed-cursor--blink {
    display: none;
    animation: none;
}

@keyframes flicker {
    from {
        opacity: 1;
    }

    25% {
        opacity: 0.7;
    }

    50% {
        opacity: 0.3;
    }

    75% {
        opacity: 0.7;
    }

    to {
        opacity: 1;
    }
}

@keyframes puls {
    from {
        transform: scale(100%);
    }

    50% {
        transform: scale(90%);
    }

    to {
        transform: scale(100%);
    }
}

.test {
    min-height: 100vh;
}

@media screen and (max-width: 1366px) {
    .App {
        position: relative;
        min-height: 100vh;
        overflow-x: hidden;
    }

    .container {
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .section__banner {
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;
        background: var(--super-light-gray);

        .banner-wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .banner__info {
                padding: 40px 80px;
                border-radius: 50px;
                background-color: #39a5b81a;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 640px;

                .banner__infoTitle {
                text-align: center;

                    color: var(--black);
                    font-size: 36px;
                    font-weight: 700;

                    
                }

                .banner__infoText {
                text-align: center;

                    margin-top: 30px;
                    max-width: 500px;
                    line-height: 180%;
                }

                .banner__form {
                    .banner__actionInp {
                        margin-top: 26px;
                        display: flex;
                        flex-direction: row;
                        gap: 30px;
                        align-items: center;

                        color: var(--dark-gray);
                        font-size: 16px;
                        font-weight: 500;

                        .banner__actionInp__num {
                            width: 300px;
                            border-radius: 50px;
                            background: var(--light-gray);
                            padding: 15px 20px;

                            transition: 0.2s;
                            border: 1px solid transparent;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        .banner__actionInp__email {
                            width: 300px;

                            border-radius: 50px;
                            background: var(--light-gray);
                            padding: 15px 20px;

                            transition: 0.2s;

                            border: 1px solid transparent;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }
                    }

                    .banner__specText {
                        margin-top: 30px;
                    }

                    .banner__specRadioGroup {
                        margin-top: 30px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        align-content: center;
                        flex-wrap: wrap;
                        gap: 14px;

                        .banner__specRadioGroup__btn {
                            input[type="radio"] {
                                display: none;
                            }

                            label {
                                font-size: 15px;
                                font-weight: 500;
                                color: var(--black);
                                display: inline-block;
                                cursor: pointer;
                                padding: 5px 15px;
                                line-height: 34px;
                                border: 1px solid #999;
                                border-radius: 50px;
                                user-select: none;
                                transition: 0.2s;
                            }

                            input[type="radio"]:checked + label {
                                color: var(--white);
                                background-color: var(--blue);
                                border-color: var(--blue);
                            }

                            &:hover {
                                input[type="radio"] + label {
                                    color: var(--white);
                                    background-color: var(--blue);
                                    border-color: var(--blue);
                                }
                            }
                        }

                        .banner__specRadioGroup__btnAllSpec {
                            font-size: 15px;
                            font-weight: 500;
                            height: 100%;
                            padding: 10px 20px;
                            border-radius: 50px;
                            user-select: none;

                            transition: 0.15s;

                            &:hover {
                                color: var(--white);
                            }

                            &:active {
                                transform: scale(99%);
                            }
                        }
                    }

                    .banner__formBottom {
                        margin-top: 30px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 15px;

                        font-size: 16px;
                        font-weight: 500;

                        .banner__formBottom__inpTown {
                            width: 100%;
                            padding: 15px;
                            border-radius: 50px;
                            background: var(--light-gray, #f2f0fe);
                            border: 1px solid transparent;

                            transition: 0.2s;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        .banner__formBottom__inpCount {
                            width: 100%;
                            padding: 15px;
                            border-radius: 50px;
                            background: var(--light-gray, #f2f0fe);
                            border: 1px solid transparent;

                            transition: 0.2s;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        
                    }
                }
            }

            .banner__img {
                display: none;
                .banner__imgAbs {
                    position: absolute;
                    content: "";
                    // left: 0;
                    // top: 0;
                }

                .s1 {
                    z-index: 10;
                    left: -20px;
                    max-width: 500px;
                    height: 520px;
                }

                .s2 {
                    z-index: 9;
                    left: 0px;
                    top: -15px;
                }

                .s3 {
                    z-index: 10;
                    left: -60px;
                    top: 140px;
                }

                .s3_1 {
                    z-index: 10;
                    left: -18px;
                    top: 158px;
                }

                .s3_text {
                    visibility: hidden;
                    opacity: 0;
                    z-index: 10;
                    left: -20px;
                    top: 190px;
                    font-size: 14px;

                    transition: 2s;
                }

                .s4 {
                    display: none;
                }

                .s4_text {
                    display: none;
                }

                .s5 {
                    transition: 0.2s;
                }

                .s6 {
                    bottom: 30px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s7 {
                    z-index: 8;
                    left: 460px;
                    top: 50px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s8 {
                    left: 450px;
                    top: 140px;
                }

                .s9 {
                    z-index: 11;
                    bottom: 96px;
                    right: 10px;
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .section__specialists {
        margin-top: 60px;
        font-family: "Montserrat", sans-serif;

        .specialists-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .specialists__title {
                font-size: 24px;
                font-weight: 600;
            }

            .specialists__cards {
                display: flex;
                flex-direction: row;
                gap: 30px;
                flex-wrap: wrap;

                .specialists__cardMore {
                    max-width: 30%;
                    width: 360px;
                    padding: 20px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    border-radius: 20px;
                    background: var(--white, #ffffff);
                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    
                }
            }
        }
    }

    .section__clientInfo {
        margin-top: 80px;
        padding-bottom: 30px;
        font-family: "Montserrat", sans-serif;

        .clientInfo-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .clientInfo__img {
                position: relative;
                width: 640px;
                height: 660px;

                .clientInfo__imgAbs {
                    position: absolute;
                }

                .c1 {
                    z-index: 10;
                    top: 40px;
                }

                .c2 {
                    left: 57px;
                    top: 90px;
                }

                .c3 {
                    left: 60px;
                    top: 30px;
                }

                .c4 {
                    right: -20px;
                    bottom: 140px;
                }

                .c5 {
                    left: 50px;
                    bottom: 170px;
                }

                .c6 {
                    left: 30px;
                    bottom: 45px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c7 {
                    right: 40px;
                    top: 80px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c8 {
                    left: -40px;
                    top: 90px;
                }

                .c8_text {
                    visibility: hidden;
                    opacity: 0;
                    left: -4px;
                    top: 140px;

                    transition: 2s;
                }

                .c9 {
                    z-index: 11;
                    left: 84px;
                    top: 174px;
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .clientInfo__content {
                display: flex;
                flex-direction: column;
                gap: 40px;

                .clientInfo__contentTitle {
                    max-width: 480px;
                    width: 100%;
                    font-size: 24px;
                    font-weight: 700;
                }

                .clientInfo__contentList {
                    display: flex;
                    flex-direction: column;
                    gap: 48px;

                    .clientInfo__contentList__item {
                        display: flex;
                        flex-direction: row;
                        gap: 14px;

                        .clientInfo__contentList__item_text {
                            font-size: 16px;
                            font-weight: 500;
                        }

                     
                    }
                }
            }
        }
    }

    .section__stats {
        padding-top: 60px;
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;
        background-color: #f7f6ff;

        .stats-wrap {
            display: flex;
            flex-direction: row;
            gap: 200px;

            .stats__left {
                display: flex;
                flex-direction: column;

                .stats__leftTitle {
                    width: 500px;
                    color: var(--black);
                    font-size: 24px;
                    font-weight: 600;

                }

                .stats__leftList {
                    margin-top: 100px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 100px;

                    .stats__leftList__item {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 20px;

                        .stats__leftList__itemNum {
                            position: relative;
                            top: -30px;
                            font-size: 100px;
                            font-weight: 900;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 100px;
                                height: 100px;
                                left: 0;
                                top: 40px;
                                border-radius: 6px;
                                animation-name: flicker;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                            }
                        }

                        .stats__leftList__itemText {
                            max-width: 300px;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 180%;
                        }
                    }
                }

                .stats__leftBtn {
                    margin-top: 60px;

                    align-self: center;
                    color: var(--white);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 20px 60px;
                    border-radius: 50px;

                    transition: 0.15s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }

            // .stats__right {
            //     display: flex;
            //     flex-direction: column;

            //     .stats__rightTitle {
            //         color: var(--black);
            //         font-size: 24px;
            //         font-weight: 600;
            //         text-wrap: nowrap;

            //         .stats__rightTitle_marked {
            //             color: #fba457;
            //             font-weight: 800;
            //         }
            //     }

            //     .stats__rightList {
            //         margin-top: 100px;
            //         display: flex;
            //         flex-direction: column;
            //         gap: 100px;

            //         .stats__rightList__item {
            //             position: relative;
            //             display: flex;
            //             flex-direction: row;
            //             align-items: center;
            //             gap: 20px;

            //             .stats__rightList__itemNum {
            //                 position: relative;
            //                 top: -30px;
            //                 color: #1dbf73;
            //                 font-size: 100px;
            //                 font-weight: 900;

            //                 &::before {
            //                     position: absolute;
            //                     content: "";
            //                     width: 100px;
            //                     height: 100px;
            //                     left: 23px;
            //                     top: 40px;
            //                     border-radius: 6px;
            //                     background: rgba(29, 191, 115, 0.2);

            //                     animation-name: flicker;
            //                     animation-duration: 2s;
            //                     animation-iteration-count: infinite;
            //                     animation-delay: 1s;
            //                 }
            //             }

            //             .stats__rightList__itemText {
            //                 max-width: 460px;
            //                 font-size: 16px;
            //                 font-weight: 500;
            //                 line-height: 180%;
            //             }
            //         }
            //     }

            //     .stats__rightBtn {
            //         margin-top: 60px;
            //         align-self: center;
            //         color: var(--white);
            //         text-align: center;
            //         font-size: 16px;
            //         font-weight: 700;
            //         padding: 20px 60px;
            //         border-radius: 50px;
            //         background: #fba457;

            //         transition: 0.15s;

            //         &:hover {
            //             background: #f19920;
            //         }

            //         &:active {
            //             background: #e28424;
            //             transform: scale(99%);
            //         }
            //     }
            // }
        }
    }

    .section__partners {
        padding-top: 50px;
        padding-bottom: 50px;
        font-family: "Montserrat", sans-serif;
        background-color: #f9f9fc;

        .partners-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .partners__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
            }

            .partners__cards {
                margin-top: 35px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 50px;

                .partners__card {
                    padding: 10px;
                    border-radius: 6px;
                    background: #fff;

                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    transition: 0.3s;

                    &:hover {
                        transform: translate(-1px, -1px);
                        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.16);
                    }
                }
            }

            .partners__text {
                margin-top: 50px;
                color: #222;
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;

               
            }
        }
    }

    .section__coop {
        padding-top: 50px;
        padding-bottom: 150px;
        font-family: "Montserrat", sans-serif;
        background-color: #f7f6ff;

        .coop-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .coop__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                /* 43.2px */
                letter-spacing: 0.48px;
            }

            .coop__content {
                width: 100%;

                margin-top: 110px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 90px;

                .coop__leftCards {
                    display: flex;
                    flex-direction: column;
                    gap: 80px;

                    .coop_card {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        border-radius: 20px;
                        background: #f2f0fe;
                        box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                        width: 530px;
                        height: 90px;

                        .coop_cardImg {
                            position: relative;
                            top: -24px;
                            left: -20px;
                            width: 92px;
                            height: 87px;
                        }

                        .coop_cardText {
                            // width: 400px;
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .coop_cardBackImg {
                            display: none;
                        }
                    }

                    .card1 {
                        position: relative;
                        left: 0px;
                    }

                    .card2 {
                        position: relative;
                        left: 0px;
                    }

                    .card3 {
                        position: relative;
                        left: 0px;
                    }

                    .card4 {
                        position: relative;
                        left: 0px;
                    }
                }

                .coop__img {
                    display: none;
                    position: relative;
                    width: 420px;
                    height: 440px;

                    .coop__imgGroup {
                        display: none;
                    }

                    .coop__imgBtn {
                        display: none;
                    }
                }
            }
        }
    }

    .section__map {
        position: static;
        padding-top: 75px;
        padding-bottom: 225px;
        height: 800px;
        font-family: "Montserrat", sans-serif;

        .mapWrap {
            display: flex;
            flex-direction: column;
            gap: 80px;
            align-items: center;

            .map__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                letter-spacing: 0.48px;
            }

            .map__bottom {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .map__bottom__text {
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 180%;
                    letter-spacing: 0.32px;
                    padding-left: 30px;
                    height: 28px;

                    
                }

                .map__bottom__box {
                    position: relative;
                    z-index: 10;
                    visibility: hidden;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 30px 20px;
                    max-width: 300px;
                    height: 100%;
                    border-radius: 20px;
                    background: #f2f0fe;

                    transition: 0.1s;
                    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                    .map__bottom__box__closeBtn {
                        position: absolute;
                        content: "";
                        right: 15px;
                        top: 15px;
                        width: 16px;
                        height: 16px;

                        &:hover {
                            svg {
                                path {
                                    transition: 0.2s;
                                    fill: #a0a0a0;
                                }
                            }
                        }
                    }
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }

                // .map__bottomInfo__box {
                //     position: relative;
                //     width: 300px;
                //     height: 186px;
                //     left: 0;
                //     top: 60px;
                //     border-radius: 20px;
                //     background: #F2F0FE;
                //     box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);
                // }
            }
        }

        @media (min-width: 768px) {
            .rf-map .district b {
                width: 28px;
                height: 28px;
                left: 6px;
                top: 28px;
            }

            .rf-map .district span {
                top: 30px;
                left: 46px;
                font-size: 20px;
                font-weight: normal;
            }

            .rf-map .district-text {
                left: 30px;
                top: 80px;
                width: calc(100% - 60px);
                height: calc(100% - 130px);
                font-size: 16px;
            }

            .rf-map .close-district {
                width: 60px;
                height: 60px;
                font-size: 60px;
            }

            .rf-map .district-links {
                display: none;
            }
        }
    }

    .about {
        padding-top: 120px;
        padding-bottom: 350px;
        font-family: "Montserrat", sans-serif;

        .aboutWrap {
            display: flex;
            flex-direction: row;
            gap: 100px;

            .about__info {
                max-width: 640px;
                display: flex;
                flex-direction: column;
                gap: 35px;

                .about__infoTitle {
                    color: #222;
                    font-size: 36px;
                    font-weight: 700;
                    letter-spacing: 1.8px;

                    .about__infoTitle_marked {
                        color: #1dbf73;
                    }
                }

                .about__infoSubTitle {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.32px;
                }

                .about__infoText {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                }
            }

            .about__img {
                width: 600px;
                height: 600px;
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    width: 420px;
                    height: 440px;
                    left: 140px;
                    top: 240px;
                    border-radius: 50%;
                    background: #a8a8a831;
                }

                .about__imgAddr {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    left: 220px;

                    .about__imgAddrText {
                        color: #000;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.32px;

                        .about__imgAddrText_marked {
                            font-weight: 500;
                        }
                    }
                }

                .about__imgAbs {
                    position: absolute;
                }

                .a1 {
                    top: 120px;
                    left: 10px;
                }

                .a2 {
                    left: 140px;
                    top: 240px;
                }

                .a3 {
                    right: -30px;
                    top: 400px;
                }

                .a4 {
                    right: 50px;
                    bottom: -158px;
                }

                .a5 {
                    top: 540px;
                    left: 140px;
                }

                .a6 {
                    width: 60px;
                    height: 30px;
                    left: 130px;
                    bottom: -60px;
                }

                .a7 {
                    right: 60px;
                    top: 200px;
                }

                .about__imgBtn {
                    text-align: center;
                    width: 300px;
                    z-index: 12;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 700;
                    padding: 10px;
                    border-radius: 50px;
                    background: #fba457;

                    left: 180px;
                    bottom: -220px;

                    transition: 0.15s;

                    &:hover {
                        background: #e28424;
                    }

                    &:active {
                        background: #e28424;
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .specialties {
        padding-top: 120px;
        padding-bottom: 380px;
        font-family: "Montserrat", sans-serif;

        .specialtiesWrap {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .specialties__title {
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0.72px;

           
            }

            .specialties__cards {
                display: flex;
                flex-direction: row;
                gap: 30px;
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .App {
        position: relative;
        min-height: 100vh;
        overflow-x: hidden;
    }

    .container {
        max-width: 1180px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .section__banner {
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;
        background: var(--super-light-gray);

        .banner-wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .banner__info {
                padding: 40px 80px;
                border-radius: 50px;
                background-color: #39a5b81a;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 640px;

                .banner__infoTitle {
                    color: var(--black);
                    font-size: 36px;
                    font-weight: 700;

                   
                }

                .banner__infoText {
                    margin-top: 30px;
                    max-width: 500px;
                    line-height: 180%;
                }

                .banner__form {
                    .banner__actionInp {
                        margin-top: 26px;
                        display: flex;
                        flex-direction: row;
                        gap: 30px;
                        align-items: center;

                        color: var(--dark-gray);
                        font-size: 16px;
                        font-weight: 500;

                        .banner__actionInp__num {
                            width: 300px;
                            border-radius: 50px;
                            background: var(--light-gray);
                            padding: 15px 20px;

                            transition: 0.2s;
                            border: 1px solid transparent;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        .banner__actionInp__email {
                            width: 300px;

                            border-radius: 50px;
                            background: var(--light-gray);
                            padding: 15px 20px;

                            transition: 0.2s;

                            border: 1px solid transparent;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }
                    }

                    .banner__specText {
                        margin-top: 30px;
                    }

                   

                    .banner__formBottom {
                        margin-top: 30px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 15px;

                        font-size: 16px;
                        font-weight: 500;

                        .banner__formBottom__inpTown {
                            width: 100%;
                            padding: 15px;
                            border-radius: 50px;
                            background: var(--light-gray, #f2f0fe);
                            border: 1px solid transparent;

                            transition: 0.2s;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        .banner__formBottom__inpCount {
                            width: 100%;
                            padding: 15px;
                            border-radius: 50px;
                            background: var(--light-gray, #f2f0fe);
                            border: 1px solid transparent;

                            transition: 0.2s;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                       
                    }
                }
            }

            .banner__img {
                position: relative;
                width: 500px;
                height: 530px;

                .banner__imgAbs {
                    position: absolute;
                    content: "";
                    // left: 0;
                    // top: 0;
                }

                .s1 {
                    z-index: 10;
                    left: -20px;
                    max-width: 500px;
                    height: 520px;
                }

                .s2 {
                    z-index: 9;
                    left: 0px;
                    top: -15px;
                }

                .s3 {
                    z-index: 10;
                    left: -60px;
                    top: 140px;
                }

                .s3_1 {
                    z-index: 10;
                    left: -18px;
                    top: 158px;
                }

                .s3_text {
                    visibility: hidden;
                    opacity: 0;
                    z-index: 10;
                    left: -20px;
                    top: 190px;
                    font-size: 14px;

                    transition: 2s;
                }

                .s4 {
                    display: none;
                }

                .s4_text {
                    display: none;
                }

                .s5 {
                    transition: 0.2s;
                }

                .s6 {
                    bottom: 30px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s7 {
                    z-index: 8;
                    left: 460px;
                    top: 50px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s8 {
                    left: 450px;
                    top: 140px;
                }

                .s9 {
                    z-index: 11;
                    bottom: 96px;
                    right: 10px;
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .section__specialists {
        margin-top: 60px;
        font-family: "Montserrat", sans-serif;

        .specialists-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .specialists__title {
                font-size: 24px;
                font-weight: 600;
            }

            .specialists__cards {
                display: flex;
                flex-direction: row;
                gap: 30px;
                flex-wrap: wrap;

                .specialists__cardMore {
                    max-width: 30%;
                    width: 330px;
                    padding: 20px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    border-radius: 20px;
                    background: var(--white, #ffffff);
                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    .specialists__cardMore__btn {
                        font-size: 18px;
                        font-weight: 600;

                        transition: 0.2s;

                        &:hover {
                        }

                        &:active {
                            transform: scale(99%);
                        }
                    }
                }
            }
        }
    }

    .section__clientInfo {
        margin-top: 80px;
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;

        .clientInfo-wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .clientInfo__img {
                display: none;
            }

            .clientInfo__content {
                display: flex;
                flex-direction: column;
                gap: 40px;

                .clientInfo__contentTitle {
                    text-align: center;
                    max-width: 480px;
                    width: 100%;
                    font-size: 24px;
                    font-weight: 700;
                }

                .clientInfo__contentList {
                    display: flex;
                    flex-direction: column;
                    gap: 48px;

                    .clientInfo__contentList__item {
                        display: flex;
                        flex-direction: row;
                        gap: 14px;

                        .clientInfo__contentList__item_text {
                            font-size: 16px;
                            font-weight: 500;
                        }

                        .clientInfo__contentList__item_mark {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }

    .section__stats {
        padding-top: 60px;
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;
        background-color: #f7f6ff;

        .stats-wrap {
            display: flex;
            flex-direction: row;
            gap: 200px;

            .stats__left {
                display: flex;
                flex-direction: column;

                .stats__leftTitle {
                    width: 500px;
                    color: var(--black);
                    font-size: 24px;
                    font-weight: 600;

                }

                .stats__leftList {
                    margin-top: 100px;

                    display: flex;
                    flex-direction: column;
                    gap: 100px;

                    .stats__leftList__item {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 20px;

                        .stats__leftList__itemNum {
                            position: relative;
                            top: -30px;
                            font-weight: 900;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 100px;
                                height: 100px;
                                left: 0;
                                top: 40px;
                                border-radius: 6px;
                                animation-name: flicker;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                            }
                        }

                        .stats__leftList__itemText {
                            max-width: 300px;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 180%;
                        }
                    }
                }

                .stats__leftBtn {
                    margin-top: 60px;

                    align-self: center;
                    color: var(--white);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 20px 60px;
                    border-radius: 50px;

                    transition: 0.15s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }

            .stats__right {
                display: flex;
                flex-direction: column;

                .stats__rightTitle {
                    position: relative;
                    left: 0px;
                    text-align: center;
                    color: var(--black);
                    font-size: 24px;
                    font-weight: 600;

                }

                .stats__rightList {
                    margin-top: 70px;
                    display: flex;
                    flex-direction: column;
                    gap: 100px;

                    .stats__rightList__item {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 20px;

                        .stats__rightList__itemNum {
                            position: relative;
                            top: -30px;
                            font-size: 100px;
                            font-weight: 900;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 100px;
                                height: 100px;
                                left: 23px;
                                top: 40px;
                                border-radius: 6px;
                                animation-name: flicker;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                                animation-delay: 1s;
                            }
                        }

                        .stats__rightList__itemText {
                            max-width: 460px;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 180%;
                        }
                    }
                }

                .stats__rightBtn {
                    margin-top: 60px;
                    align-self: center;
                    color: var(--white);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 20px 60px;
                    border-radius: 50px;

                    transition: 0.15s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .section__partners {
        padding-top: 50px;
        padding-bottom: 50px;
        font-family: "Montserrat", sans-serif;
        background-color: #f9f9fc;

        .partners-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .partners__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
            }

            .partners__cards {
                margin-top: 35px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 25px;

                .partners__card {
                    padding: 10px;
                    border-radius: 6px;
                    background: #fff;

                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    transition: 0.3s;

                    &:hover {
                        transform: translate(-1px, -1px);
                        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.16);
                    }
                }
            }

            .partners__text {
                margin-top: 50px;
                color: #222;
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;

            }
        }
    }

    .section__coop {
        padding-top: 50px;
        padding-bottom: 150px;
        font-family: "Montserrat", sans-serif;
        background-color: #f7f6ff;

        .coop-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .coop__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                /* 43.2px */
                letter-spacing: 0.48px;
            }

            .coop__content {
                width: 100%;

                margin-top: 110px;
                display: flex;
                flex-direction: row;
                gap: 90px;

                

               
            }
        }
    }

    .section__map {
        position: static;
        padding-top: 75px;
        padding-bottom: 250px;
        height: 800px;
        font-family: "Montserrat", sans-serif;

        .mapWrap {
            display: flex;
            flex-direction: column;
            gap: 80px;
            align-items: center;

            .map__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                letter-spacing: 0.48px;
            }

            .map__bottom {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .map__bottom__text {
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 180%;
                    letter-spacing: 0.32px;
                    padding-left: 30px;
                    height: 28px;
                }

                .map__bottom__box {
                    position: relative;
                    z-index: 10;
                    visibility: hidden;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 30px 20px;
                    max-width: 300px;
                    height: 100%;
                    border-radius: 20px;
                    background: #f2f0fe;

                    transition: 0.1s;
                    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                    .map__bottom__box__closeBtn {
                        position: absolute;
                        content: "";
                        right: 15px;
                        top: 15px;
                        width: 16px;
                        height: 16px;

                        &:hover {
                            svg {
                                path {
                                    transition: 0.2s;
                                    fill: #a0a0a0;
                                }
                            }
                        }
                    }
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }

                // .map__bottomInfo__box {
                //     position: relative;
                //     width: 300px;
                //     height: 186px;
                //     left: 0;
                //     top: 60px;
                //     border-radius: 20px;
                //     background: #F2F0FE;
                //     box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);
                // }
            }
        }

        @media (min-width: 768px) {
            .rf-map .district b {
                width: 28px;
                height: 28px;
                left: 6px;
                top: 28px;
            }

            .rf-map .district span {
                top: 30px;
                left: 46px;
                font-size: 20px;
                font-weight: normal;
            }

            .rf-map .district-text {
                left: 30px;
                top: 80px;
                width: calc(100% - 60px);
                height: calc(100% - 130px);
                font-size: 16px;
            }

            .rf-map .close-district {
                width: 60px;
                height: 60px;
                font-size: 60px;
            }

            .rf-map .district-links {
                display: none;
            }
        }
    }

    .about {
        padding-top: 120px;
        padding-bottom: 350px;
        font-family: "Montserrat", sans-serif;

        .aboutWrap {
            display: flex;
            flex-direction: row;
            gap: 0;

            .about__info {
                max-width: 640px;
                display: flex;
                flex-direction: column;
                gap: 35px;

                .about__infoTitle {
                    color: #222;
                    font-size: 36px;
                    font-weight: 700;
                    letter-spacing: 1.8px;

                    .about__infoTitle_marked {
                        color: #1dbf73;
                    }
                }

                .about__infoSubTitle {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.32px;
                }

                .about__infoText {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                }
            }

            .about__img {
                width: 600px;
                height: 600px;
                position: relative;

                &::before {
                    position: absolute;
                    content: "";
                    width: 420px;
                    height: 440px;
                    left: 140px;
                    top: 240px;
                    border-radius: 50%;
                    background: #a8a8a831;
                }

                .about__imgAddr {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    left: 220px;

                    .about__imgAddrText {
                        color: #000;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: 0.32px;

                        .about__imgAddrText_marked {
                            font-weight: 500;
                        }
                    }
                }

                .about__imgAbs {
                    position: absolute;
                }

                .a1 {
                    top: 120px;
                    left: 10px;
                }

                .a2 {
                    left: 140px;
                    top: 240px;
                }

                .a3 {
                    right: -30px;
                    top: 400px;
                }

                .a4 {
                    right: 20px;
                    bottom: -174px;
                }

                .a5 {
                    top: 540px;
                    left: 140px;
                }

                .a6 {
                    width: 60px;
                    height: 30px;
                    left: 130px;
                    bottom: -60px;
                }

                .a7 {
                    right: 60px;
                    top: 200px;
                }

                .about__imgBtn {
                    text-align: center;
                    width: 300px;
                    z-index: 12;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 700;
                    padding: 10px;
                    border-radius: 50px;
                    background: #fba457;

                    left: 180px;
                    bottom: -220px;

                    transition: 0.15s;

                    &:hover {
                        background: #e28424;
                    }

                    &:active {
                        background: #e28424;
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .specialties {
        padding-top: 120px;
        padding-bottom: 380px;
        font-family: "Montserrat", sans-serif;

        .specialtiesWrap {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .specialties__title {
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0.72px;

            
            }

            .specialties__cards {
                display: flex;
                flex-direction: row;
                gap: 30px;
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 820px) {
    .container {
        max-width: 760px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .moveTop {
        transform: translateY(40px);
    }

    .section__banner {
        padding-top: 80px;
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;

        .banner-wrap {
            display: flex;
            flex-direction: row;

            .banner__info {
                max-width: 700px;

                display: flex;
                flex-direction: column;

                .banner__infoTitle {
                    color: var(--black);
                    font-size: 36px;
                    font-weight: 700;
                    text-align: center;

                    
                }

                .banner__infoText {
                    margin-top: 30px;
                    max-width: 100%;
                    line-height: 180%;
                }

              
            }

            .banner__img {
                display: none;

                .banner__imgAbs {
                    position: absolute;
                    content: "";
                    // left: 0;
                    // top: 0;
                }

                .s1 {
                    z-index: 10;
                    left: -20px;
                    max-width: 500px;
                    height: 520px;
                }

                .s2 {
                    z-index: 9;
                    left: 0px;
                    top: -15px;
                }

                .s3 {
                    z-index: 10;
                    left: -60px;
                    top: 140px;
                }

                .s3_1 {
                    z-index: 10;
                    left: -18px;
                    top: 158px;
                }

                .s3_text {
                    visibility: hidden;
                    opacity: 0;
                    z-index: 10;
                    left: -20px;
                    top: 190px;
                    font-size: 14px;

                    transition: 2s;
                }

                .s4 {
                    display: none;
                }

                .s4_text {
                    display: none;
                }

                .s5 {
                    transition: 0.2s;
                }

                .s6 {
                    bottom: 30px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s7 {
                    z-index: 8;
                    left: 460px;
                    top: 50px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s8 {
                    left: 450px;
                    top: 140px;
                }

                .s9 {
                    z-index: 11;
                    bottom: 96px;
                    right: 10px;
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .section__specialists {
        margin-top: 60px;
        font-family: "Montserrat", sans-serif;

        .specialists-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .specialists__title {
                text-align: center;
                font-size: 24px;
                font-weight: 600;
            }

            .specialists__cards {
                display: flex;
                flex-direction: row;
                gap: 65px;
                flex-wrap: wrap;

                .specialists__cardMore {
                    max-width: 40%;
                    width: 330px;
                    padding: 20px;

                    display: flex;
                    align-items: center;
                    text-align: center;

                    border-radius: 20px;
                    background: var(--white, #ffffff);
                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    
                }
            }
        }
    }

    .section__clientInfo {
        margin-top: 80px;
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;

        .clientInfo-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .clientInfo__img {
                display: none;
                position: relative;
                width: 640px;
                height: 660px;

                .clientInfo__imgAbs {
                    position: absolute;
                }

                .c1 {
                    z-index: 10;
                    top: 40px;
                }

                .c2 {
                    left: 57px;
                    top: 90px;
                }

                .c3 {
                    left: 60px;
                    top: 30px;
                }

                .c4 {
                    right: -20px;
                    bottom: 140px;
                }

                .c5 {
                    left: 50px;
                    bottom: 170px;
                }

                .c6 {
                    left: 30px;
                    bottom: 45px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c7 {
                    right: 40px;
                    top: 80px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c8 {
                    left: -40px;
                    top: 90px;
                }

                .c8_text {
                    visibility: hidden;
                    opacity: 0;
                    left: -4px;
                    top: 140px;

                    transition: 2s;
                }

                .c9 {
                    z-index: 11;
                    left: 84px;
                    top: 174px;
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .clientInfo__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 40px;
                width: 100%;

                .clientInfo__contentTitle {
                    text-align: center;
                    max-width: 100%;
                    width: 100%;
                    font-size: 24px;
                    font-weight: 700;
                }

                .clientInfo__contentList {
                    display: flex;
                    flex-direction: column;
                    gap: 40px;
                    width: 70%;

                    .clientInfo__contentList__item {
                        display: flex;
                        flex-direction: row;
                        gap: 14px;

                        .clientInfo__contentList__item_text {
                            font-size: 16px;
                            font-weight: 500;
                        }

                    }
                }
            }
        }
    }

    .section__stats {
        padding-top: 60px;
        padding-bottom: 60px;
        font-family: "Montserrat", sans-serif;
        background-color: #f7f6ff;

        .stats-wrap {
            display: flex;
            flex-direction: column;
            gap: 100px;

            .stats__left {
                display: flex;
                flex-direction: column;
                align-items: center;

                .stats__leftTitle {
                    text-align: center;
                    width: 500px;
                    color: var(--black);
                    font-size: 24px;
                    font-weight: 600;
                }

                .stats__leftList {
                    margin-top: 100px;

                    display: flex;
                    flex-direction: column;
                    gap: 100px;

                    .stats__leftList__item {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 20px;

                        .stats__leftList__itemNum {
                            position: relative;
                            top: -30px;
                            font-size: 100px;
                            font-weight: 900;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 100px;
                                height: 100px;
                                left: 0;
                                top: 40px;
                                border-radius: 6px;
                                animation-name: flicker;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                            }
                        }

                        .stats__leftList__itemText {
                            max-width: 300px;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 180%;
                        }
                    }
                }

                .stats__leftBtn {
                    margin-top: 60px;

                    align-self: center;
                    color: var(--white);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 20px 60px;
                    border-radius: 50px;

                    transition: 0.15s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }

            .stats__right {
                display: flex;
                flex-direction: column;
                align-items: center;

                .stats__rightTitle {
                    text-align: center;
                    position: relative;
                    left: 0px;

                    color: var(--black);
                    font-size: 24px;
                    font-weight: 600;


                }

                .stats__rightList {
                    margin-top: 100px;
                    display: flex;
                    flex-direction: column;
                    gap: 100px;
                    width: 60%;

                    .stats__rightList__item {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 0px;

                        .stats__rightList__itemNum {
                            position: relative;
                            top: -30px;
                            font-size: 100px;
                            font-weight: 900;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 100px;
                                height: 100px;
                                left: 23px;
                                top: 40px;
                                border-radius: 6px;

                                animation-name: flicker;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                                animation-delay: 1s;
                            }
                        }

                        .stats__rightList__itemText {
                            max-width: 460px;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 180%;
                        }
                    }
                }

                .stats__rightBtn {
                    margin-top: 60px;
                    align-self: center;
                    color: var(--white);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 20px 60px;
                    border-radius: 50px;

                    transition: 0.15s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .section__partners {
        padding-top: 50px;
        padding-bottom: 50px;
        font-family: "Montserrat", sans-serif;
        background-color: #f9f9fc;

        .partners-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .partners__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
            }

            .partners__cards {
                margin-top: 35px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 58px;

                .partners__card {
                    padding: 10px;
                    border-radius: 6px;
                    background: #fff;

                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    transition: 0.3s;

                    &:hover {
                        transform: translate(-1px, -1px);
                        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.16);
                    }
                }
            }

            .partners__text {
                margin-top: 50px;
                color: #222;
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
            }
        }
    }

    .section__coop {
        padding-top: 50px;
        padding-bottom: 75px;
        font-family: "Montserrat", sans-serif;
        background-color: #f7f6ff;

        .coop-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .coop__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                /* 43.2px */
                letter-spacing: 0.48px;
            }

            .coop__content {
                width: 100%;

                margin-top: 55px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 90px;

                .coop__leftCards {
                    display: flex;
                    flex-direction: column;
                    gap: 80px;

                    .coop_card {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        border-radius: 20px;
                        background: #f2f0fe;
                        box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                        width: 530px;
                        height: 90px;

                        .coop_cardImg {
                            position: relative;
                            top: -24px;
                            left: -20px;
                            width: 92px;
                            height: 87px;
                        }

                        .coop_cardText {
                            // width: 400px;
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .coop_cardBackImg {
                            position: absolute;
                            right: -80px;
                            top: 14px;
                            z-index: 11;
                        }
                    }

                    .card1 {
                        position: static;
                        // left: 0px;
                    }

                    .card2 {
                        position: static;
                        // left: 0px;
                    }

                    .card3 {
                        position: static;
                        // left: 0px;
                    }

                    .card4 {
                        position: static;
                        // left: 0px;
                    }
                }

                .coop__img {
                    display: none;
                    position: relative;
                    width: 420px;
                    height: 440px;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 420px;
                        height: 440px;
                        left: 110px;
                        top: 110px;
                        border-radius: 50%;
                        background: #a8a8a831;
                    }

                    .coop__imgAbs {
                        position: absolute;
                        transition: 0.2s;
                    }

                    .e1 {
                        z-index: 10;
                        left: -20px;
                    }

                    .e2 {
                        left: 110px;
                        top: 110px;
                    }

                    .e3 {
                        right: -170px;
                        top: 200px;
                    }

                    .e4 {
                        bottom: -20px;
                        left: 115px;
                    }

                    .e5 {
                        right: -95px;
                        bottom: -200px;
                    }

                    .e6 {
                        width: 60px;
                        height: 30px;
                        left: 100px;
                        bottom: -100px;
                    }

                    .e7 {
                        right: -100px;
                        top: 100px;
                    }

                    .coop__imgBtn {
                        text-align: center;
                        width: 300px;
                        z-index: 12;
                        color: var(--white);
                        font-size: 16px;
                        font-weight: 700;
                        padding: 10px;
                        border-radius: 50px;
                        background: #1dbf73;

                        left: 160px;
                        bottom: -250px;

                        transition: 0.15s;

                        &:hover {
                            background: #12b758;
                        }

                        &:active {
                            background: #0e9347;
                            transform: scale(99%);
                        }
                    }
                }
            }
        }
    }

    .section__map {
        position: static;
        padding-top: 75px;
        padding-bottom: 150px;
        height: 800px;
        font-family: "Montserrat", sans-serif;

        .mapWrap {
            display: flex;
            flex-direction: column;
            gap: 80px;
            align-items: center;

            .map__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                letter-spacing: 0.48px;
            }

            .map__bottom {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .map__bottom__text {
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 180%;
                    letter-spacing: 0.32px;
                    padding-left: 30px;
                    height: 28px;
                }

                .map__bottom__box {
                    position: relative;
                    z-index: 10;
                    visibility: hidden;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 30px 20px;
                    max-width: 300px;
                    height: 100%;
                    border-radius: 20px;
                    background: #f2f0fe;

                    transition: 0.1s;
                    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                    .map__bottom__box__closeBtn {
                        position: absolute;
                        content: "";
                        right: 15px;
                        top: 15px;
                        width: 16px;
                        height: 16px;

                        &:hover {
                            svg {
                                path {
                                    transition: 0.2s;
                                    fill: #a0a0a0;
                                }
                            }
                        }
                    }
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }

                // .map__bottomInfo__box {
                //     position: relative;
                //     width: 300px;
                //     height: 186px;
                //     left: 0;
                //     top: 60px;
                //     border-radius: 20px;
                //     background: #F2F0FE;
                //     box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);
                // }
            }
        }

        @media (min-width: 768px) {
            .rf-map .district b {
                width: 28px;
                height: 28px;
                left: 6px;
                top: 28px;
            }

            .rf-map .district span {
                top: 30px;
                left: 46px;
                font-size: 20px;
                font-weight: normal;
            }

            .rf-map .district-text {
                left: 30px;
                top: 80px;
                width: calc(100% - 60px);
                height: calc(100% - 130px);
                font-size: 16px;
            }

            .rf-map .close-district {
                width: 60px;
                height: 60px;
                font-size: 60px;
            }

            .rf-map .district-links {
                display: none;
            }
        }

        /* Цвета регионов */
        // .rf-map [data-code="RU-SAR"] {
        //     fill: #116f21;
        //     cursor: pointer;
        // }

        // .rf-map [data-code="RU-SA"] {
        //     fill: #d17520;
        //     cursor: pointer;
        // }

        // .rf-map [data-code="RU-KO"] {
        //     fill: #901aad;
        // }

        // .rf-map [data-code="RU-SAR"]:hover {
        //     fill: #1bad33;
        // }

        // .rf-map [data-code="RU-SA"]:hover {
        //     fill: #e3954b;
        // }

        // .rf-map [data-code="RU-KO"]:hover {
        //     fill: #bf37e1;
        // }
    }

    .about {
        .container {
            max-width: 760px;
            margin: 0 auto;
            padding: 0;
        }

        padding-top: 80px;
        padding-bottom: 350px;
        font-family: "Montserrat", sans-serif;

        .aboutWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 80px;

            .about__info {
                text-align: justify;
                max-width: 90%;
                display: flex;
                flex-direction: column;
                gap: 35px;

                .about__infoTitle {
                    text-align: center;
                    color: #222;
                    font-size: 30px;
                    font-weight: 700;
                    letter-spacing: 1.8px;

                    .about__infoTitle_marked {
                        color: #1dbf73;
                    }
                }

                .about__infoSubTitle {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.32px;
                }

                .about__infoText {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                }
            }

            .about__img {
                display: none;
            }

            .about__addrAdaptive {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .about__textBtn {
                    margin-top: 20px;
                    align-self: center;
                    text-align: center;
                    width: 200px;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 700;
                    padding: 10px;
                    border-radius: 50px;
                    background: #fba457;
                    transition: 0.15s;

                    &:hover {
                        background: #e28424;
                    }

                    &:active {
                        background: #e28424;
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .specialties {
        padding-top: 80px;
        padding-bottom: 380px;
        font-family: "Montserrat", sans-serif;

        .specialtiesWrap {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .specialties__title {
                text-align: center;
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0.72px;

              
            }

            .specialties__cards {
                display: flex;
                flex-direction: row;
                gap: 65px;
                flex-wrap: wrap;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .container {
        max-width: 560px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .section__banner {
        padding-top: 80px;
        padding-bottom: 60px;
        min-height: calc(100vh - 120px);
        font-family: "Montserrat", sans-serif;
        background: var(--super-light-gray);

        .banner-wrap {

            display: flex;
            flex-direction: row;

            .banner__info {
                max-width: 500px;
                padding: 20px 40px;

                display: flex;
                flex-direction: column;
                width: 100%;

                .banner__infoTitle {
                    color: var(--black);
                    font-size: 36px;
                    font-weight: 700;
                    text-align: center;

          
                }

                .banner__infoText {
                    margin-top: 30px;
                    max-width: 100%;
                    line-height: 180%;
                }

                .banner__form {
                    max-width: 500px;

                    .banner__actionInp {
                        margin-top: 26px;
                        display: flex;
                        flex-direction: row;
                        gap: 30px;
                        align-items: center;

                        color: var(--dark-gray);
                        font-size: 16px;
                        font-weight: 500;

                        .banner__actionInp__num {
                            width: 300px;
                            border-radius: 50px;
                            background: var(--light-gray);
                            padding: 15px 20px;

                            transition: 0.2s;
                            border: 1px solid transparent;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        .banner__actionInp__email {
                            width: 300px;

                            border-radius: 50px;
                            background: var(--light-gray);
                            padding: 15px 20px;

                            transition: 0.2s;

                            border: 1px solid transparent;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }
                    }

                    .banner__specText {
                        margin-top: 30px;
                    }

                   

                    .banner__formBottom {
                        margin-top: 30px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 15px;

                        font-size: 16px;
                        font-weight: 500;

                        .banner__formBottom__inpTown {
                            width: 100%;
                            padding: 15px;
                            border-radius: 50px;
                            background: var(--light-gray, #f2f0fe);
                            border: 1px solid transparent;

                            transition: 0.2s;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        .banner__formBottom__inpCount {
                            width: 100%;
                            padding: 15px;
                            border-radius: 50px;
                            background: var(--light-gray, #f2f0fe);
                            border: 1px solid transparent;

                            transition: 0.2s;

                            &:hover {
                                border: 0.5px solid #d0cde1;
                            }

                            &:focus {
                                border: 0.5px solid #d0cde1;
                            }
                        }

                        
                    }
                }
            }

            .banner__img {
                display: none;

                .banner__imgAbs {
                    position: absolute;
                    content: "";
                    // left: 0;
                    // top: 0;
                }

                .s1 {
                    z-index: 10;
                    left: -20px;
                    max-width: 500px;
                    height: 520px;
                }

                .s2 {
                    z-index: 9;
                    left: 0px;
                    top: -15px;
                }

                .s3 {
                    z-index: 10;
                    left: -60px;
                    top: 140px;
                }

                .s3_1 {
                    z-index: 10;
                    left: -18px;
                    top: 158px;
                }

                .s3_text {
                    visibility: hidden;
                    opacity: 0;
                    z-index: 10;
                    left: -20px;
                    top: 190px;
                    font-size: 14px;

                    transition: 2s;
                }

                .s4 {
                    display: none;
                }

                .s4_text {
                    display: none;
                }

                .s5 {
                    transition: 0.2s;
                }

                .s6 {
                    bottom: 30px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s7 {
                    z-index: 8;
                    left: 460px;
                    top: 50px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .s8 {
                    left: 450px;
                    top: 140px;
                }

                .s9 {
                    z-index: 11;
                    bottom: 96px;
                    right: 10px;
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .section__specialists {
        margin-top: 60px;
        font-family: "Montserrat", sans-serif;

        .specialists-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .specialists__title {
                text-align: center;
                font-size: 24px;
                font-weight: 600;
            }

            .specialists__cards {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 65px;

                .specialists__cardMore {
                    max-width: 100%;
                    width: 350px;
                    padding: 20px;
                    height: 100px;

                    display: flex;
                    align-items: center;
                    text-align: center;

                    border-radius: 20px;
                    background: var(--white, #ffffff);
                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    .specialists__cardMore__btn {
                        font-size: 18px;
                        font-weight: 600;

                        transition: 0.2s;

                        &:hover {
                        }

                        &:active {
                            transform: scale(99%);
                        }
                    }
                }
            }
        }
    }

    .section__clientInfo {
        margin-top: 80px;
        padding-bottom: 30px;
        font-family: "Montserrat", sans-serif;

        .clientInfo-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .clientInfo__img {
                display: none;
                position: relative;
                width: 640px;
                height: 660px;

                .clientInfo__imgAbs {
                    position: absolute;
                }

                .c1 {
                    z-index: 10;
                    top: 40px;
                }

                .c2 {
                    left: 57px;
                    top: 90px;
                }

                .c3 {
                    left: 60px;
                    top: 30px;
                }

                .c4 {
                    right: -20px;
                    bottom: 140px;
                }

                .c5 {
                    left: 50px;
                    bottom: 170px;
                }

                .c6 {
                    left: 30px;
                    bottom: 45px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c7 {
                    right: 40px;
                    top: 80px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c8 {
                    left: -40px;
                    top: 90px;
                }

                .c8_text {
                    visibility: hidden;
                    opacity: 0;
                    left: -4px;
                    top: 140px;

                    transition: 2s;
                }

                .c9 {
                    z-index: 11;
                    left: 84px;
                    top: 174px;
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .clientInfo__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 40px;

                .clientInfo__contentTitle {
                    margin: 0 10px;
                    text-align: center;
                    max-width: 100%;
                    width: 100%;
                    font-size: 24px;
                    font-weight: 700;
                }

                .clientInfo__contentList {
                    display: flex;
                    flex-direction: column;
                    gap: 48px;

                }
            }
        }
    }

  

    .section__partners {
        padding-top: 50px;
        padding-bottom: 50px;
        font-family: "Montserrat", sans-serif;
        background-color: #f9f9fc;

        .partners-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .partners__title {
                text-align: center;
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
            }

            .partners__cards {
                width: 110%;
                height: 100%;
                overflow-x: scroll;
                margin-top: 70px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 60px;

                .partners__card {
                    padding: 10px;
                    border-radius: 6px;
                    background: #fff;

                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    transition: 0.3s;

                    &:hover {
                        transform: translate(-1px, -1px);
                        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.16);
                    }
                }
            }

            .partners__text {
                text-align: center;
                margin-top: 60px;
                color: #222;
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;

                .partners__text_marked {
                    font-size: 32px;
                    font-weight: 800;
                    line-height: 180%;
                }
            }
        }
    }

    .section__coop {
        padding-top: 50px;
        padding-bottom: 75px;
        font-family: "Montserrat", sans-serif;
        background-color: #f7f6ff;

        .coop-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .coop__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                /* 43.2px */
                letter-spacing: 0.48px;
            }

            .coop__content {
                width: 100%;

                margin-top: 55px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 90px;

                .coop__leftCards {
                    display: flex;
                    flex-direction: column;
                    gap: 80px;

                    .coop_card {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        border-radius: 20px;
                        background: #f2f0fe;
                        box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                        width: 530px;
                        height: 90px;

                        .coop_cardImg {
                            position: relative;
                            top: -24px;
                            left: -20px;
                            width: 92px;
                            height: 87px;
                        }

                        .coop_cardText {
                            // width: 400px;
                            font-size: 14px;
                            font-weight: 400;
                        }

                        .coop_cardBackImg {
                            position: absolute;
                            right: -80px;
                            top: 14px;
                            z-index: 11;
                        }
                    }

                    .card1 {
                        position: static;
                        // left: 0px;
                    }

                    .card2 {
                        position: static;
                        // left: 0px;
                    }

                    .card3 {
                        position: static;
                        // left: 0px;
                    }

                    .card4 {
                        position: static;
                        // left: 0px;
                    }
                }

                .coop__img {
                    display: none;
                    position: relative;
                    width: 420px;
                    height: 440px;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 420px;
                        height: 440px;
                        left: 110px;
                        top: 110px;
                        border-radius: 50%;
                        background: #a8a8a831;
                    }

                    .coop__imgAbs {
                        position: absolute;
                        transition: 0.2s;
                    }

                    .e1 {
                        z-index: 10;
                        left: -20px;
                    }

                    .e2 {
                        left: 110px;
                        top: 110px;
                    }

                    .e3 {
                        right: -170px;
                        top: 200px;
                    }

                    .e4 {
                        bottom: -20px;
                        left: 115px;
                    }

                    .e5 {
                        right: -95px;
                        bottom: -200px;
                    }

                    .e6 {
                        width: 60px;
                        height: 30px;
                        left: 100px;
                        bottom: -100px;
                    }

                    .e7 {
                        right: -100px;
                        top: 100px;
                    }

                    .coop__imgBtn {
                        text-align: center;
                        width: 300px;
                        z-index: 12;
                        color: var(--white);
                        font-size: 16px;
                        font-weight: 700;
                        padding: 10px;
                        border-radius: 50px;
                        background: #1dbf73;

                        left: 160px;
                        bottom: -250px;

                        transition: 0.15s;

                        &:hover {
                            background: #12b758;
                        }

                        &:active {
                            background: #0e9347;
                            transform: scale(99%);
                        }
                    }
                }
            }
        }
    }

    .section__map {
        position: static;
        padding-top: 75px;
        padding-bottom: 0px;
        height: 800px;
        font-family: "Montserrat", sans-serif;

        .mapWrap {
            display: flex;
            flex-direction: column;
            gap: 80px;
            align-items: center;

            .map__title {
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                letter-spacing: 0.48px;
            }

            .map__bottom {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .map__bottom__text {
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 180%;
                    letter-spacing: 0.32px;
                    padding-left: 30px;
                    height: 28px;

                    
                }

                .map__bottom__box {
                    position: relative;
                    z-index: 10;
                    visibility: hidden;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 30px 20px;
                    max-width: 300px;
                    height: 100%;
                    border-radius: 20px;
                    background: #f2f0fe;

                    transition: 0.1s;
                    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                    .map__bottom__box__closeBtn {
                        position: absolute;
                        content: "";
                        right: 15px;
                        top: 15px;
                        width: 16px;
                        height: 16px;

                        &:hover {
                            svg {
                                path {
                                    transition: 0.2s;
                                    fill: #a0a0a0;
                                }
                            }
                        }
                    }
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }

                // .map__bottomInfo__box {
                //     position: relative;
                //     width: 300px;
                //     height: 186px;
                //     left: 0;
                //     top: 60px;
                //     border-radius: 20px;
                //     background: #F2F0FE;
                //     box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);
                // }
            }
        }

        @media (min-width: 768px) {
            .rf-map .district b {
                width: 28px;
                height: 28px;
                left: 6px;
                top: 28px;
            }

            .rf-map .district span {
                top: 30px;
                left: 46px;
                font-size: 20px;
                font-weight: normal;
            }

            .rf-map .district-text {
                left: 30px;
                top: 80px;
                width: calc(100% - 60px);
                height: calc(100% - 130px);
                font-size: 16px;
            }

            .rf-map .close-district {
                width: 60px;
                height: 60px;
                font-size: 60px;
            }

            .rf-map .district-links {
                display: none;
            }
        }
    }

    .about {
        .container {
            max-width: 760px;
            margin: 0 auto;
            padding: 0;
        }

        padding-top: 80px;
        padding-bottom: 350px;
        font-family: "Montserrat", sans-serif;

        .aboutWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 80px;

            .about__info {
                text-align: justify;
                max-width: 90%;
                display: flex;
                flex-direction: column;
                gap: 35px;

                .about__infoTitle {
                    text-align: center;
                    color: #222;
                    font-size: 30px;
                    font-weight: 700;
                    letter-spacing: 1.8px;

                    .about__infoTitle_marked {
                        color: #1dbf73;
                    }
                }

                .about__infoSubTitle {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.32px;
                }

                .about__infoText {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                }
            }

            .about__img {
                display: none;
            }

            .about__addrAdaptive {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .about__textBtn {
                    margin-top: 20px;
                    align-self: center;
                    text-align: center;
                    width: 200px;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 700;
                    padding: 10px;
                    border-radius: 50px;
                    background: #fba457;
                    transition: 0.15s;

                    &:hover {
                        background: #e28424;
                    }

                    &:active {
                        background: #e28424;
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .specialties {
        padding-top: 80px;
        padding-bottom: 380px;
        font-family: "Montserrat", sans-serif;

        .specialtiesWrap {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .specialties__title {
                text-align: center;
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0.72px;

           
            }

            .specialties__cards {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 65px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .container {
        max-width: 375px;
        margin: 0 auto;
        padding: 0 10px;
    }

    .App {
        overflow-x: hidden;
    }

    .moveTop {
        transform: translateY(40px);
    }

    .section__banner {
        margin-top: 0px;
        background: transparent;
        padding-bottom: 20px;
        // overflow-x: hidden;

        .banner-wrap {
        max-width: 350px;

            flex-direction: column-reverse;
            justify-content: center;

            .banner__info {
        max-width: 350px;

                width: 100%;

                .banner__infoTitle {
                    text-align: center;
                    font-size: 30px;

                    .banner__infoTitle_marked {
                    }
                }

                .banner__infoText {
                }

                .banner__form {
        max-width: 350px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .banner__actionInp {
                        width: 85%;
                        flex-direction: column;
                        gap: 20px;

                        .banner__actionInp__num {
                        }

                        .banner__actionInp__email {
                        }
                    }

                    .banner__specText {
                        max-width: 80%;
                    }

                    .banner__specRadioGroup {
                        margin-top: 20px;
                        white-space: nowrap;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        overflow-x: scroll;
                    }

                    .banner__formBottom {
                        margin-top: 20px;
                        display: flex;
                        width: 85%;
                        flex-direction: column;

                        .banner__formBottom__btnSend {
                            width: 60%;
                        }
                    }
                }
            }

            .banner__img {
                display: none;
            }
        }
    }

    .section__specialists {
        // overflow-x: hidden;

        .specialists-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .specialists__title {
                font-size: 24px;
                font-weight: 600;
            }

            .specialists__cards {
                display: flex;
                flex-direction: column;
                gap: 30px;
                flex-wrap: nowrap;

                .specialists__cardMore {
                    max-width: 100%;
                    width: 350px;
                    height: 60px;
                    // padding-top: 20px;
                    // padding-bottom: 20px;
                    padding-left: 0px;
                    padding-right: 0px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    border-radius: 20px;
                    background: var(--white, #ffffff);
                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    
                }
            }
        }
    }

    .section__clientInfo {
        margin-top: 70px;
        // overflow-x: hidden;

        .clientInfo-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .clientInfo__img {
                display: none;
                position: relative;
                width: 640px;
                height: 660px;

                .clientInfo__imgAbs {
                    position: absolute;
                }

                .c1 {
                    z-index: 10;
                    top: 40px;
                }

                .c2 {
                    left: 57px;
                    top: 90px;
                }

                .c3 {
                    left: 60px;
                    top: 30px;
                }

                .c4 {
                    right: -20px;
                    bottom: 140px;
                }

                .c5 {
                    left: 50px;
                    bottom: 170px;
                }

                .c6 {
                    left: 30px;
                    bottom: 45px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c7 {
                    right: 40px;
                    top: 80px;
                    animation-name: puls;
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                }

                .c8 {
                    left: -40px;
                    top: 90px;
                }

                .c8_text {
                    left: -4px;
                    top: 140px;
                }

                .c9 {
                    z-index: 11;
                    left: 84px;
                    top: 174px;
                }
            }

            .clientInfo__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 40px;

                .clientInfo__contentTitle {
                    text-align: center;
                    max-width: 340px;
                    font-size: 18px;
                    font-weight: 700;
                }

                .clientInfo__contentList {
                    display: flex;
                    flex-direction: column;
                    gap: 36px;

                    .clientInfo__contentList__item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 14px;

                        .clientInfo__contentList__item_text {
                            max-width: 300px;
                            font-size: 16px;
                            font-weight: 500;
                        }

                        
                    }
                }
            }
        }
    }

    .section__stats {
        padding-top: 25px;
        overflow-x: hidden;

        .stats-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 100px;

            .stats__left {
                display: flex;
                flex-direction: column;
                align-items: center;

                .stats__leftTitle {
                    text-align: center;
                    width: 320px;
                    color: var(--black);
                    font-size: 24px;
                    font-weight: 600;

      
                }

                .stats__leftList {
                    margin-top: 85px;

                    display: flex;
                    flex-direction: column;
                    gap: 60px;

                    .stats__leftList__item {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 20px;

                        .stats__leftList__itemNum {
                            position: relative;
                            top: -30px;
                            font-size: 70px;
                            font-weight: 900;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 60px;
                                height: 60px;
                                left: 0;
                                top: 40px;
                                border-radius: 6px;
                                animation-name: flicker;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                            }
                        }

                        .stats__leftList__itemText {
                            max-width: 270px;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 180%;
                        }
                    }
                }

                .stats__leftBtn {
                    margin-top: 60px;

                    align-self: center;
                    color: var(--white);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 20px;
                    border-radius: 50px;

                    transition: 0.15s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }

            .stats__right {
                display: flex;
                flex-direction: column;
                align-items: center;

                .stats__rightTitle {
                    white-space: wrap;
                    text-align: center;
                    width: 320px;
                    color: var(--black);
                    font-size: 24px;
                    font-weight: 600;

   
                }

                .stats__rightList {
                    margin-top: 85px;
                    display: flex;
                    flex-direction: column;
                    gap: 60px;
                    width: 100%;

                    .stats__rightList__item {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 20px;

                        .stats__rightList__itemNum {
                            position: relative;
                            top: -30px;
                            font-size: 70px;
                            font-weight: 900;

                            &::before {
                                position: absolute;
                                content: "";
                                width: 60px;
                                height: 60px;
                                left: 23px;
                                top: 40px;
                                border-radius: 6px;

                                animation-name: flicker;
                                animation-duration: 2s;
                                animation-iteration-count: infinite;
                                animation-delay: 1s;
                            }
                        }

                        .stats__rightList__itemText {
                            max-width: 240px;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 180%;
                        }
                    }
                }

                .stats__rightBtn {
                    margin-top: 60px;
                    align-self: center;
                    color: var(--white);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 20px;
                    border-radius: 50px;

                    transition: 0.15s;

                    &:hover {
                    }

                    &:active {
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .section__partners {
        overflow-x: visible;

        .partners-wrap {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // align-items: center;

            .partners__title {
                text-align: center;
                font-size: 24px;
                font-weight: 600;
            }

            .partners__cards {
                width: 130%;
                height: 100%;
                overflow-x: scroll;
                margin-top: 70px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 60px;

                .partners__card {
                    padding: 10px;
                    border-radius: 6px;
                    background: #fff;

                    box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.04);

                    transition: 0.3s;

                    &:hover {
                        transform: translate(-1px, -1px);
                        box-shadow: 0px 16px 16px 0px rgba(34, 34, 34, 0.16);
                    }

                    &:first-child {
                        margin-left: 130px;
                    }

                    &:last-child {
                        margin-right: 130px;
                    }
                }
            }

            .partners__text {
                text-align: center;
                margin-top: 60px;
                font-size: 16px;
                font-weight: 600;

                
            }
        }
    }

    .section__coop {
        display: none;
    }

    .section__map {
        position: static;
        padding-top: 75px;
        padding-bottom: 0px;
        height: 800px;
        font-family: "Montserrat", sans-serif;

        .mapWrap {
            flex-direction: column;
            gap: 40px;
            align-items: center;

            .map__title {
                text-align: center;
                font-size: 24px;
                font-weight: 600;
                line-height: 180%;
                letter-spacing: 0.48px;
            }

            .map__bottom {
                display: none;

                position: relative;
                width: 100%;
                flex-direction: column;
                gap: 30px;

                .map__bottom__text {
                    position: relative;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 180%;
                    letter-spacing: 0.32px;
                    padding-left: 30px;
                    height: 28px;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 20px;
                        height: 20px;
                        left: 0;
                        top: 2.5px;
                        border-radius: 100px;
                        background: #fba457;
                    }
                }

                .map__bottom__box {
                    position: relative;
                    z-index: 10;
                    visibility: hidden;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    padding: 30px 20px;
                    max-width: 300px;
                    height: 100%;
                    border-radius: 20px;
                    background: #f2f0fe;

                    transition: 0.1s;
                    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);

                    .map__bottom__box__closeBtn {
                        position: absolute;
                        content: "";
                        right: 15px;
                        top: 15px;
                        width: 16px;
                        height: 16px;

                        &:hover {
                            svg {
                                path {
                                    transition: 0.2s;
                                    fill: #a0a0a0;
                                }
                            }
                        }
                    }
                }

                .visible {
                    visibility: visible;
                    opacity: 1;
                }

                // .map__bottomInfo__box {
                //     position: relative;
                //     width: 300px;
                //     height: 186px;
                //     left: 0;
                //     top: 60px;
                //     border-radius: 20px;
                //     background: #F2F0FE;
                //     box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.04);
                // }
            }

            .map__bottomMedia {
                display: block;
            }
        }

        @media (min-width: 768px) {
            .rf-map .district b {
                width: 28px;
                height: 28px;
                left: 6px;
                top: 28px;
            }

            .rf-map .district span {
                top: 30px;
                left: 46px;
                font-size: 20px;
                font-weight: normal;
            }

            .rf-map .district-text {
                left: 30px;
                top: 80px;
                width: calc(100% - 60px);
                height: calc(100% - 130px);
                font-size: 16px;
            }

            .rf-map .close-district {
                width: 60px;
                height: 60px;
                font-size: 60px;
            }

            .rf-map .district-links {
                display: none;
            }
        }
    }

    .about {
        .container {
            max-width: 760px;
            margin: 0 auto;
            padding: 0;
        }

        padding-top: 80px;
        padding-bottom: 350px;
        font-family: "Montserrat", sans-serif;

        .aboutWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 80px;

            .about__info {
                text-align: justify;
                max-width: 90%;
                display: flex;
                flex-direction: column;
                gap: 35px;

                .about__infoTitle {
                    text-align: center;
                    color: #222;
                    font-size: 30px;
                    font-weight: 700;
                    letter-spacing: 1.8px;

                    .about__infoTitle_marked {
                        color: #1dbf73;
                    }
                }

                .about__infoSubTitle {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.32px;
                }

                .about__infoText {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                }
            }

            .about__img {
                display: none;
            }

            .about__addrAdaptive {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .about__textBtn {
                    margin-top: 20px;
                    align-self: center;
                    text-align: center;
                    width: 200px;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 700;
                    padding: 10px;
                    border-radius: 50px;
                    background: #fba457;
                    transition: 0.15s;

                    &:hover {
                        background: #e28424;
                    }

                    &:active {
                        background: #e28424;
                        transform: scale(99%);
                    }
                }
            }
        }
    }

    .specialties {
        padding-top: 80px;
        padding-bottom: 380px;
        font-family: "Montserrat", sans-serif;

        .specialtiesWrap {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .specialties__title {
                text-align: center;
                font-size: 36px;
                font-weight: 600;
                letter-spacing: 0.72px;

            }

            .specialties__cards {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 65px;
            }
        }
    }
}
