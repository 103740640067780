@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Montserrat:wght@400;500;700&display=swap");

:root {
    --black: #222;
    --dark: #584a4a;
    --white: #fff;
    --green: #1dbf73;
    --dark-green: #0e9347;
    --light-light-gray: #f6fffe;
    --light-gray: #f2f0fe;
    --dark-gray: #656084;
    --violet: #4A3270;
    --light-violet: #735a9b;
    --vaiolit: #D3085D;
    --red: #ff0000;
    --orange: #ff993f;
    --dark-orange: #ff7700;
    --super-light-gray: #f7f6ff;
    --blue: #16A4D1;
    --light-blue:#16a5d1b2;
}
body {
    min-height: 100vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}



code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

* {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
    text-decoration: none;
}

li,
a {
    color: #000;
    text-decoration: none;
}

button,
input, textarea {
    all: unset;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #838383;
}

::-webkit-scrollbar-thumb {
    background-color: #5a5a5a;
}