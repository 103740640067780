.map__mapImg {
    position: absolute;
    width: 100%;
}

.rf-map,
.rf-map * {
    width: 100%;
    box-sizing: border-box;
}

.rf-map {
    position: relative;
    max-width: 980px;
    margin: auto;
    padding: 20px;
}

.rf-map .district b {
    position: absolute;
    z-index: 5;
    width: 18px;
    height: 18px;
    background: rgba(51, 122, 183, 0.9);
    border-radius: 50%;
    left: 6px;
    top: 18px;
    transition: all 0.3s;
}

.rf-map .district span {
    position: absolute;
    z-index: 5;
    top: 20px;
    left: 30px;
    font-size: 13px;
    font-weight: bold;
    font-family: Tahoma, sans-serif;
    line-height: 1;
    color: #337AB7;
}

.rf-map .district {
    display: none;
}

.rf-map.open .district b {
    top: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2), 0 16px 20px rgba(0, 0, 0, 0.2);
}

.rf-map.open .district span {
    color: #FFF;
}

.rf-map svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 5px 12px rgba(0, 0, 0, 0.5));
}

.rf-map path {
    stroke: #F19920;
    stroke-width: 1;
    stroke-linejoin: round;
}

.rf-map [data-code] {
    fill: #FFEDDD;
    transition: fill 0.2s;
}


.rf-map .rf-clickMarked {
    fill: #CBEEE1 !important;
    stroke: #1DBF73;

    path {
        stroke: #1DBF73;

    }
}

.rf-map .rf-empty {
    fill: #D0CDE1;
    pointer-events: none;

}
// .rf-map .rf-disabled {
//     pointer-events: none;
// }


.rf-map [data-code]:hover {
    fill: #FFD6B2;
}


.rf-map * .district-text {
    display: none;
    position: absolute;
    z-index: 6;
    left: 0;
    top: 60px;
    width: calc(100% - 20px);
    height: calc(100% - 90px);
    padding: 0 20px;
    overflow: auto;
    color: #FFF;
    font-size: 13px;
    font-family: Verdana, sans-serif;
}

.rf-map .close-district {
    opacity: 0;
    z-index: 6;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    line-height: 1;
    font-size: 40px;
    color: #fff;
    cursor: pointer;
    transition: opacity 1s;
    pointer-events: none;
}

.rf-map.open .close-district {
    opacity: 1;
    pointer-events: initial;
}

.rf-map .district-links {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.rf-map .district-links div {
    font-size: 13px;
    font-family: Verdana, sans-serif;
    padding: 4px;
}

.rf-map .district-links div:after {
    content: " | ";
    color: #337AB7;
}

.rf-map .district-links div:last-child:after {
    content: "";
}

.dropfill {
    fill: #337AB7 !important;
}

.mainfill {
    fill: #1d4567 !important;
}