.container {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0;
}

.scroll {
    background-color: #f6fafd;
}
.header {
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: 0.5s;
    background-color: #F6FAFD;

    .header__wrap {
        padding: 20px 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        font-family: "Montserrat", sans-serif;


        .header__logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            font-size: 30px;
            font-weight: 500;
            color: var(--blue);
        }

        .header__nav {
            .header__navList {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 40px;

                font-size: 16px;
                font-weight: 500;

                .header__navItem {
                    .header__navLink {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        position: relative;
                        transition: 0.3s;

                        &::before {
                            position: absolute;
                            content: "";
                            width: 0;
                            height: 1px;
                            left: 0;
                            bottom: -2px;
                            background: var(--blue);

                            transition: 0.3s;
                        }

                        &:hover {
                            color: var(--blue);

                            svg {
                                path {
                                    transition: 0.3s;
                                    fill: var(--blue);
                                }
                            }

                            &::before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .mediaHeader__town {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            .header__town {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                user-select: none;

                .town__text {
                    text-wrap: nowrap;
                    font-size: 14px;
                    font-weight: 400;
                }

                .town__btn {
                    cursor: pointer;

                    max-width: 95px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    // padding: 10px 15px;
                    // border-radius: 50px;

                    color: var(--blue);
                    font-size: 16px;
                    font-weight: 600;
                    // background-color: var(--light-gray);

                    transition: 0.2s;

                    &:hover {
                        color: var(--light-blue);
                    }

                    &:active {
                        color: var(--light-blue);
                        transform: scale(99%);
                    }
                }
            }
            .mediaTownCall {
                display: none;
                width: 25px;
                height: 25px;
                .mediaTownCall_Link {
                    .mediaTownCall_LinkImg {
                    }
                }
            }
        }

        .header__orderBtn {
            cursor: pointer;
            text-wrap: nowrap;
            user-select: none;
            color: var(--white);
            padding: 10px 30px;
            border-radius: 50px;
            background: var(--blue);
            font-size: 16px;
            font-weight: 600;
            // border: 1px var(--white) solid;

            transition: 0.15s;

            &:hover {
                background-color: var(--light-blue);
            }

            &:active {
                transform: scale(99%);
            }
        }
        .header__messageBtn {
            cursor: pointer;
            text-wrap: nowrap;
            user-select: none;
            color: var(--white);
            padding: 10px 30px;
            border-radius: 50px;
            background: var(--blue);
            font-size: 16px;
            font-weight: 600;

            transition: 0.15s;

            &:hover {
                background-color: var(--light-blue);
            }

            &:active {
                transform: scale(99%);
            }
        }

        .mediaHeader__order {
            display: none;
            flex-direction: row;
            gap: 10px;
            align-items: center;
        }
    }
}

@media screen and (max-width: 1366px) {
    .container {
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .header {
        max-width: 1366px;
        background-color: #f6fafd;
        position: fixed;
        width: 100%;

        .header__wrap {
            padding: 20px 0;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            font-family: "Montserrat", sans-serif;


            .header__nav {
                .header__navList {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 40px;

                    font-size: 16px;
                    font-weight: 500;

                    .header__navItem {
                        .header__navLink {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            position: relative;
                            transition: 0.3s;
                        }
                    }
                }
            }
            .mediaHeader__town {
                display: flex;
                flex-direction: row;
                gap: 12px;
                align-items: center;
                .header__town {


                    .town__text {
                        display: none;
                        text-wrap: nowrap;
                        font-size: 14px;
                        font-weight: 400;
                    }

                   
                }
                .mediaTownCall {
                    display: none;
                    width: 25px;
                    height: 25px;
                    .mediaTownCall_Link {
                        .mediaTownCall_LinkImg {
                        }
                    }
                }
            }

            

            .mediaHeader__order {
                display: none;
                flex-direction: row;
                gap: 15px;
                align-items: center;

                .mediaHeader__orderMenu {
                    width: 25px;
                    height: 25px;
                    transform: rotate(90deg);

                    transition: 1s;
                }
                .menuOpened {
                    transform: rotate(720deg);
                }

                .mediaHeader__orderPages {
                    width: 25px;
                    height: 25px;
                }
                .mediaHeader__orderRequest {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}
@media screen and (max-width: 1240px) {
    .container {
        max-width: 1180px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .header {
        position: fixed;
        .header__wrap {
            padding: 10px 5px;
            .header__logo {
                .header__logo1 {
                    width: 30px;
                    height: 30px;
                }
                .header__logo2 {
                    font-size: 24px;
                }
            }

            .header__nav {
                display: none;
            }

            .mediaHeader__town {
                .header__town {
                    padding: 5px 10px;
                    .town__text {
                        display: none;
                    }

                    .town__btn {
                    }
                }
                .mediaTownCall {
                    display: block;
                    .mediaTownCall_Link {
                        .mediaTownCall_LinkImg {
                        }
                    }
                }
            }

            .header__orderBtn {
                display: none;
            }
            .header__messageBtn {
                display: none;
            }

            .mediaHeader__order {
                display: flex;
            }
        }
    }
}
@media screen and (max-width: 820px) {
    ::-webkit-scrollbar {
        width: 10px;
        background-color: #838383;
    }
    .container {
        max-width: 600px;
        margin: 0 auto;
        padding: 0 15px;
    }
    .header {
        position: fixed;
        .header__wrap {
            padding: 10px 5px;
            .header__logo {
                font-size: 20px;
            }

            .header__nav {
                display: none;
            }

            .mediaHeader__town {
                .header__town {
                    padding: 5px 10px;
                    .town__text {
                        display: none;
                    }

                    .town__btn {
                    }
                }
                .mediaTownCall {
                    display: block;
                    .mediaTownCall_Link {
                        .mediaTownCall_LinkImg {
                        }
                    }
                }
            }

            .header__orderBtn {
                display: none;
            }

            .mediaHeader__order {
                display: flex;
            }
        }
    }
}
@media screen and (max-width: 450px) {
    .container {
        max-width: 350px;
        margin: 0 auto;
        padding: 0;
    }
    .header {
        width: 100%;
        left: 0;
        right: 0;
        .header__wrap {
            padding: 16px 5px;
            .header__logo {
                font-size: 16px;
            }

            .header__nav {
                display: none;
            }

            .mediaHeader__town {
                .header__town {
                    max-width: 70px;
                    padding: 2px 6px;

                    .town__text {
                        display: none;
                    }

                    .town__btn {
                        font-size: 14px;
                    }
                }
                .mediaTownCall {
                    display: block;
                    width: 20px;
                    height: 20px;
                    .mediaTownCall_Link {
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .header__orderBtn {
                display: none;
            }

            .mediaHeader__order {
                display: flex;
                align-items: center;
                gap: 10px;

                .mediaHeader__orderMenu {
                }
                .mediaHeader__orderPages {
                }
                .mediaHeader__orderRequest {
                }

                .mediaMenuBtn {
                    width: 20px;
                    height: 20px;
                }

                .mediaImg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .container {
        max-width: 320px;
        margin: 0 auto;
        padding: 5px;
    }
    .header {
        width: 100%;
        left: 0;
        right: 0;
        .header__wrap {
            padding: 10px 5px;
            .header__logo {
            }

            .header__nav {
                display: none;
            }

            .mediaHeader__town {
                margin-right: 10px;
                .header__town {
                    max-width: 70px;
                    padding: 2px 6px;
                    .town__text {
                        display: none;
                    }

                    .town__btn {
                        font-size: 14px;
                    }
                }
                .mediaTownCall {
                    display: block;
                    width: 20px;
                    height: 20px;
                    .mediaTownCall_Link {
                        .mediaTownCall_LinkImg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .header__orderBtn {
                display: none;
            }

            .mediaHeader__order {
                gap: 10px;
                display: flex;
                .mediaHeader__orderMenu {
                    width: 20px;
                    height: 20px;
                }
                .mediaHeader__orderPages {
                    width: 20px;
                    height: 20px;
                }
                .mediaHeader__orderRequest {
                    width: 20px;
                    height: 20px;
                }

                .mediaImg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
