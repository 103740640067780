// изменить иконку чата!!!

.headerSub {
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 40px;
    background: #f0f0f0;
    transition: 1s;
    padding-top: 10px;
    padding-bottom: 4px;

    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .header__nav {
        .header__navList {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 40px;

            font-size: 16px;
            font-weight: 500;

            .header__navItem {
                .header__navLink {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    position: relative;
                    transition: 0.3s;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 0;
                        height: 1px;
                        left: 0;
                        bottom: -2px;
                        background: var(--green);

                        transition: 0.3s;
                    }

                    &:hover {
                        color: var(--green);

                        svg {
                            path {
                                transition: 0.3s;
                                fill: var(--green);
                            }
                        }

                        &::before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.visible {
    top: 44px;
}
